import React from 'react';
import axios from 'axios';
import toastr from 'toastr';
import config from '../../config/config';
import SocialButton from './SocialButton';

import googleIcon from '../../assets/img/google-icon.svg';

const {GOOGLE_ID = ''} = config;

class GoogleButton extends React.Component {
  constructor(props) {
    super(props);
    this.loginSuccess = this.loginSuccess.bind(this);
  }

  async loginSuccess(response) {
    const {
      success = () => {
      },
    } = this.props;
    const res = await axios.post('/auth/google', response);
    if (res.status === 200) {
      // Show toastr notification
      toastr.success('Login successful');

      // Trigger client login
      success();
    } else {
      toastr.error(res.data.message);
    }
  }

  render() {
    return (
        <SocialButton
            className={`login-google btn btn-secondary ${this.props.class}`}
            provider={'google'}
            appId={GOOGLE_ID}
            onLoginSuccess={this.loginSuccess}
            // onLoginSuccess={() => {console.log('test')}}
            onLoginFailure={() => false}
        >
          <img src={googleIcon} className="social-icon-img-g" alt="ngo" />
          <span className="social-icon-txt">GOOGLE</span>
        </SocialButton>
    );
  }
}

export default GoogleButton;
