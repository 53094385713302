import React from 'react'
import recommendIcon from "../../assets/img/campaign/recommend-icon.svg";
import {Button} from "reactstrap";

class RecommendButton extends React.Component {

    render() {
        const {onClick} = this.props;
        return (
            <div className={'trend-button-div bottom orange'} style={{marginTop: '55px'}}>
                <Button className="btn-round recommend-button"
                        color="primary" type="button"
                        onClick={onClick}>
                    <span>Recommend</span>
                    <img className="recommendIcon" src={recommendIcon} alt={`Recommend`}/>
                </Button>
            </div>
        );
    }
}

export default RecommendButton;
