const initialState = {
    campaign_data: {}
  };
  
  const campaignReducer = (state = initialState, action) => {
    switch (action.type) {
      case "campaign_object":
        return {
            campaign_data: action.payload
        };
      default:
        return state;
    }
  };
  
  export default campaignReducer;