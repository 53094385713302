
import React from "react";
import axios from 'axios';

//import { Card } from "reactstrap";
import '../../assets/css/style.css';
import counter1 from '../../assets/img/counters/counter1.svg';
import counter2 from '../../assets/img/counters/counter2.svg';
import counter3 from '../../assets/img/counters/counter3.svg';
import why1 from '../../assets/img/counters/why1.svg';
import why2 from '../../assets/img/counters/why2.svg';
import why3 from '../../assets/img/counters/why3.svg';


class HomeCounterSection extends React.Component {
    constructor(props) {
        super(props)
        this.numberWithCommas = this.numberWithCommas.bind(this);
        this.state = {
            supporters: 0,
            totalCampaigns: 0,
            fundRaised: 0,
          }
    }
    componentDidMount(){
        axios.get("count/User", {
            headers: {
              "Content-Type": "application/json",
              'Authorization': ''
            },
          }).then(res => {
            const supporters = res.data;
            this.setState({supporters: supporters});
          }).catch(e => console.log(e));

        axios.get("count/Campaign", {
            params: {
              status: ['Completed', 'Active'],
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': ''
            },
          }).then(res => {
            const totalCampaigns = res.data;
            this.setState({totalCampaigns: totalCampaigns});
          }).catch(e => console.log(e));


          axios.get("sum/Campaign/pledgeAmtFull", {
            headers: {
              "Content-Type": "application/json",
              'Authorization': ''
            },
          }).then(res => {
            const fundRaised = res.data;
            this.setState({fundRaised: fundRaised});
          }).catch(e => console.log(e));

    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    render() {
        return <div>
            <div className="container" style={{ zIndex: '1000',position: 'relative' }}>
                <div className="row counter-cont-row">
                    <div className="col-md-4 counter-devider">
                        <img src={counter2}  alt="supporters" />
                        <div className="home-counter-text">Supporters</div>
                        <div className="home-counter-amount">{this.numberWithCommas(this.state.supporters)}</div>
                    </div>
                    <div className="col-md-4 counter-devider">
                        <img src={counter3}  alt="total campaigns" />
                        <div className="home-counter-text">Total Campaigns</div>
                        <div className="home-counter-amount">{this.numberWithCommas(this.state.totalCampaigns)}</div>
                    </div>
                    <div className="col-md-4" style={{paddingTop: '10px'}}>
                        <img src={counter1}  alt="fund raised" />
                        <div className="home-counter-text">Funds Raised (RM)</div>
                        <div className="home-counter-amount">{this.numberWithCommas(this.state.fundRaised)}</div>
                    </div>
                </div>
            </div>
            <div style={{marginTop: '-135px'}}>
                <div className="row" style={{ textAlign: 'center', marginLeft: '0px', marginRight: '0px' }}>
                    <div className="col-md-12" style={{background: '#fff'}}>
                        <div className="row why-maybank-heart-yellow">
                            <div className="container">
                                <div className="row" style={{paddingTop: '180px',paddingBottom: '42px'}}>
                                    <div className="col-md-12">
                                        <div className="whyMaybankHeart">Why MaybankHeart?</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4" style={{paddingTop: '10px'}}>
                                        <img src={why1} alt="supporters" />
                                        <div className="why-counter-label">100% For Charity</div>
                                        <div className="why-counter-text">We don't use your donation for our administrative expenses. Every dollar you donate (minus transfer charges, if any) goes directly to the charity of your choice.</div>
                                    </div>
                                    <div className="col-md-4" style={{paddingTop: '10px'}}>
                                        <img src={why2} alt="total campaigns" />
                                        <div className="why-counter-label">We Do The Vetting</div>
                                        <div className="why-counter-text">Only pre-approved NGOs & campaigns are allowed. That means, we do the due diligence for your peace of mind.</div>
                                    </div>
                                    <div className="col-md-4" style={{paddingTop: '10px'}}>
                                        <img src={why3}  alt="funds raised" />
                                        <div className="why-counter-label">Transparent</div>
                                        <div className="why-counter-text">Amount of donations & funds are visible to everyone. This translates to being able to see the progress of the campaign.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="why-maybank-heart-white"></div> */}
        </div>;
    }
}

export default HomeCounterSection;
