
import React from "react";
import axios from 'axios';
import ReactGA from 'react-ga4';
import sha256_digest from '../../components/Scripts/satellizerSHA256';
import { Button, Card, Form, Row, Col } from "reactstrap";
import '../../assets/css/style.css';
import toastr from 'toastr';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../config/config';

import close from '../../assets/img/close.svg';

import terms from '../../pdf/TandCofMaybankHeart.pdf';
import privacy from '../../pdf/CookiePolicy.pdf';

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader";
import DemoFooter from "components/Footers/DemoFooter.js";

import userObjectAction from "../../actions/userAction";
import { connect } from "react-redux";
import FacebookButton from '../../components/Buttons/FacebookButton';
import GoogleButton from '../../components/Buttons/GoogleButton';

const DELAY = 1000;

class RegisterPage extends React.Component {
  constructor(props) {
    super(props)
    this.go2HomePage = this.go2HomePage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      loginfields: { username: '', email: '', password: '', confirmpassword: '' },
      errors: {},
      //checked: true,
      newsLetterVal: 'YES',
      captchaKey: config.Captcha_Key,

      callback: 'not fired',
      captchaValue: '',
      load: false,
      expired: 'false',
      hide: true
    }
  }
  componentDidMount(){
    config.signupPageHeader = 'yes';
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ load: true });
  }, DELAY);
  }

  handleChange(e) {
    let loginfields = this.state.loginfields;
    loginfields[e.target.name] = e.target.value;
    this.setState({
      loginfields
    });
  }
  hidePassword = () => this.setState(({hide}) => ({
    hide: !hide
  }))
  // handleCheck(e) {
  //   this.setState({ checked: !this.state.checked });
  // }
  handleChangeRecaptcha = captchaValue => {
    this.setState({ captchaValue });
    if (captchaValue === null) this.setState({ expired: "true" });
};

asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
};
  go2HomePage() {
    this.props.history.push('/');
  };
  handleValidation() {
    let loginfields = this.state.loginfields;
    let errors = {};
    let formIsValid = true;

    if (!loginfields["username"]) {
      formIsValid = false;
      errors["username"] = "*Your name is required";
    } else if (!loginfields["username"].match(/^[A-Za-z'\s.,\-@]{5,60}$/)) {
      formIsValid = false;
      errors["username"] = "*Please enter valid name";
    }
    if (!loginfields["email"]) {
      formIsValid = false;
      errors["email"] = "*Your email address is required";
    } else if (!loginfields["email"].match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      formIsValid = false;
      errors["email"] = "*Your email address is invalid.";
    }
    if (!loginfields["password"]) {
      formIsValid = false;
      errors["password"] = "*Password is required.";
    } else if (!loginfields["password"].match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      formIsValid = false;
      errors["password"] = "*Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
    }
    if (!loginfields["confirmpassword"]) {
      formIsValid = false;
      errors["confirmpassword"] = "*Confirm password is required.";
    } else if (!loginfields["confirmpassword"].match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      formIsValid = false;
      errors["confirmpassword"] = "*Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  formSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      if(this.state.loginfields.password === this.state.loginfields.confirmpassword){
        if (this.state.captchaValue !== '') {
          // if (this.state.checked) {
          //   this.setState({ newsLetterVal: 'YES' });
          // } else {
          //   this.setState({ newsLetterVal: 'NO' });
          // }
          const headers = {
            'Content-Type': 'application/json',
          }
          const data = {
            displayName: this.state.loginfields.username,
            email: this.state.loginfields.email,
            password: sha256_digest(JSON.stringify(this.state.loginfields.password)),
            newsLetter: this.state.newsLetterVal,
            reCaptcha: this.state.captchaValue
          }
          axios.post(config.APP_URL + "auth/signup", data, {
            headers: headers
          }).then(res => {
            if(res.status === 200){
              ReactGA.event({
                category: "User",
                action: "Registered successfully",
              });
              this.props.userObjectAction(res.data);
              //window.localStorage.setItem('userDetails', JSON.stringify(res.data));
              toastr.success('Registration has been success');
              this.props.history.push('/');
            }else {
              toastr.error(res.data.message);
            }
          }).catch(e => {
            ReactGA.event({
              category: "User",
              action: "Register unsuccessful",
            });
            toastr.error(e.response.data.message);
          })
         } else {
           alert('Please enter the recaptcha');
        }
      }else {
        alert('Password and confirm password must be same.');
      }
    } else {
      console.log("Form has errors.");
    }
  }


  render() {
    const { load } = this.state || {};
    return <div style={{ background: '#F0F0F0' }}>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div className="container">
        <Row>
          <Col className="ml-auto mr-auto" lg="4">
            <Card className="card-register ml-auto mr-auto">
              <div><span className="title-login mx-auto">SIGN UP</span><span style={{ float: 'right' }}> <img src={close} onClick={this.go2HomePage} style={{ width: '18px', cursor: 'pointer' }} alt="ngo" /></span></div>
              <div style={{ fontSize: '12px' }}>with your social network</div>
              <div className="text-center1" style={{ marginBottom: '15px', marginTop: '-8px' }}>
                <FacebookButton success={this.go2HomePage} />
                <GoogleButton success={this.go2HomePage} />
              </div>
              <div className="weneverpost"> We’ll never post anything without your permission</div>
              <div><p className="pclass"><span className="Orclass">or</span></p></div>
              <Form className="register-form" onSubmit={this.formSubmit.bind(this)}>
                <div style={{ marginBottom: '15px' }}>
                  <input type="text" placeholder="Name" className="login-field" name="username"
                    onChange={this.handleChange} value={this.state.loginfields.username}
                  />
                  <div className="errMsg">{this.state.errors.username}</div>
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <input type="text" placeholder="Email" className="login-field" name="email"
                    onChange={this.handleChange} value={this.state.loginfields.email}
                  />
                  <div className="errMsg">{this.state.errors.email}</div>
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <input type={this.state.hide ? 'password' : 'input'} placeholder="Password" className="login-field" name="password"
                    onChange={this.handleChange} value={this.state.loginfields.password}
                  />
                  <i className={`fa ${this.state.hide === true ? 'fa-eye-slash' : 'fa-eye'} password-eye`}
                     onClick={this.hidePassword}></i>
                    {/* <img src={eyeIcon} className="eyeicon" alt="ngo" /> */}
                  <div className="errMsg">{this.state.errors.password}</div>
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <input type={this.state.hide ? 'password' : 'input'} placeholder="Confirm Password" className="login-field" name="confirmpassword"
                    onChange={this.handleChange} value={this.state.loginfields.confirmpassword}
                  />
                  <i className={`fa ${this.state.hide === true ? 'fa-eye-slash' : 'fa-eye'} password-eye`}
                     onClick={this.hidePassword}></i>
                  <div className="errMsg">{this.state.errors.confirmpassword}</div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                  {load && (
                    <ReCAPTCHA
                      theme="dark"
                      ref={(r) => this._reCaptchaRef = r}
                      sitekey={this.state.captchaKey}
                      onChange={this.handleChangeRecaptcha}
                      asyncScriptOnLoad={this.asyncScriptOnLoad}
                    />
                  )}
                </div>
                {/* <div>
                  <Label check>
                    <input type="checkbox" className="chekTerms" onChange={this.handleCheck} defaultChecked={this.state.checked} style={{ cursor: 'pointer' }} />
                    <span className="recivupdates">Receive updates & our newsletter</span> <span className="form-check-sign" />
                  </Label>
                </div> */}
                <div className="register-termsofuse">
                  <span className="bysignup">By signing up, you agree to our </span>
                  <span><a href={terms} target = "_blank" rel="noopener noreferrer" className="terms-signup">Terms</a></span><span>and</span>
                  <span><a href={privacy} target = "_blank" rel="noopener noreferrer" className="terms-signup">Privacy Policy</a></span>
                </div>
                <Button block className="support-button-payment" style={{marginTop: '20px', fontSize: '14px'}}>
                  SIGN UP
                  </Button>
              </Form>
              {/* <div style={{paddingTop:'20px'}}><p className="pclass"></p></div> */}
           {/* <div style={{marginTop: '-5px'}}><span className="getsupt"> Get Support through donations.</span> <span className="signupasNgo">Sign up as NGO</span> </div> */}
            </Card>
          </Col>
        </Row>
      </div>
      <DemoFooter />

    </div>;
  }
}

//export default RegisterPage;
const mapStateToProps = state => {
  return {
    campaign_details: state.campaign
  };
};

const mapDispatchToProps = dispatch => ({
  userObjectAction: (payload) => dispatch(userObjectAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
