import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {Button, Modal} from "reactstrap";
import ReactPlayer from "react-player";
import "./slider.sass";

import learnMore from "../../assets/img/campaign/eye-icon.svg";
import watchVideo from "../../assets/img/campaign/video-icon.svg";
import supportIcon from "../../assets/img/campaign/support-icon.svg";

export default function Slide({image, active}) {
  const history = useHistory();
  const [modal, setModal] = React.useState(false);
  const watchVideoModal = () => {
    setModal(!modal);
  };
  const imageGetSize = () => {
    const width = window.innerWidth;
    if (width > 768) {
      return image.imageDesktop;
    } else if (width > 414) {
      return image.imageTablet;
    } else {
      return image.imageMobile;
    }
  };

  const [imageSrc, setSrc] = useState(imageGetSize());

  const go2DetailsPage = (campid) => {
    history.push({
      pathname: "/campaign-details-page",
      search: "Campaign=" + campid,
      //search: 'Campaign=580738a99133b10b806e9f48',
    });
  };
  const go2SupportPage = (campid) => {
    axios.get("api/Campaign?_id=" + campid, {
      //axios.get("api/Campaign?_id=580738a99133b10b806e9f48", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "",
      },
    }).then(res => {
      const campDetails = res.data.data;
      //console.log(JSON.stringify(campDetails));

      history.push({
        pathname: "/support-page",
        campaignData: campDetails,
      });
    }).catch(e => console.log(e));
  };
  // Check screen width
  useEffect(() => {
    window.addEventListener("resize", () => {
      setSrc(imageGetSize());
    });

    return () => {
      window.removeEventListener("resize", () => {
        setSrc(imageGetSize());
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  return (
      <div className={"Slide" + (active ? " active" : "")} style={
        {
          // backgroundImage: `linear-gradient(to bottom, #00000000, #000000A3), url(${imageSrc.default ?
          //     imageSrc.default :
          //     imageSrc})`,
          backgroundImage: `linear-gradient(to bottom, rgba(31, 26, 26, 0.5), #000000A3), url(${imageSrc.default ?
              imageSrc.default :
              imageSrc})`,
        }
      }>
        <div className="container banner-text-div">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="home-banner-title">{image.title}</div>
              <div className="home-banner-desc">{image.description}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
              <div className="row">
                {image.campaign.length > 0 ?
                    <div
                        className="col-lg-3 col-md-3 col-sm-6 col-xs-6 padleftright0">
                      <Button
                          className="btn-round ml-1 home-banner-learnmore-btn"
                          color="default" type="button"
                          onClick={() => go2DetailsPage(image.campaign)}>
                        LEARN MORE <img src={learnMore}
                                        className="home-banner-btn-icon"
                                        alt="Learn More"/>
                      </Button></div>
                    : null
                }
                {image.external && image.external.length > 0 ?
                    <div
                        className="col-lg-3 col-md-3 col-sm-6 col-xs-6 padleftright0">
                      <Button
                          className="btn-round ml-1 home-banner-learnmore-btn"
                          color="default" type="button">
                        <a target="_blank" rel="noopener noreferrer"
                           href={image.external}
                           className="myTrt"> Visit Page <img
                            src={learnMore} className="home-banner-btn-icon"
                            alt=""/></a>
                      </Button>
                    </div>
                    : null
                }
                {image.video.length > 0 ?
                    <div
                        className="col-lg-3 col-md-3 col-sm-6 col-xs-6 padleftright0">
                      <Button
                          className="btn-round ml-1 home-banner-learnmore-btn"
                          color="default" type="button"
                          onClick={watchVideoModal}>
                        WATCH VIDEO <img src={watchVideo}
                                         className="home-banner-btn-icon"
                                         alt="Watch Video"/>
                      </Button>
                    </div>
                    : null
                }
                {image.campaign.length > 0 ?
                    <div
                        className="col-lg-3 col-md-3 col-sm-6 col-xs-6 padleftright0">
                      <Button className="btn-round ml-1 home-banner-support-btn"
                              color="default" type="button"
                              onClick={() => go2SupportPage(image.campaign)}>
                        SUPPORT <img src={supportIcon}
                                     className="home-banner-btn-icon-support"
                                     alt="Support Campaign"/>
                      </Button>
                    </div>
                    : null
                }
                {image.treatPoints.length > 0 ?
                    <div
                        className="col-lg-3 col-md-3 col-sm-6 col-xs-6 padleftright0">
                      <Button className="btn-round ml-1 home-banner-support-btn"
                              color="default" type="button">
                        <a target="_blank" rel="noopener noreferrer"
                           href={image.treatPoints}
                           className="myTrt"> MYTREATS <img
                            src={supportIcon}
                            className="home-banner-btn-icon-support"
                            alt="Treats Points"/></a>
                      </Button>
                    </div>
                    : null
                }
                <div>
                  <Modal isOpen={modal} toggle={watchVideoModal}>
                    <div className="modal-header">
                      <button
                          aria-label="Close"
                          className="close"
                          type="button"
                          onClick={watchVideoModal}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                      <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                      >
                        Watch the video
                      </h5>
                    </div>
                    <div className="modal-body" style={{
                      padding: "20px 20px 20px 20px",
                      height: "350px",
                    }}>
                      <ReactPlayer url={image.video} width="100%" height="100%"
                                   controls={true} playing={true} />
                    </div>
                  </Modal>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
