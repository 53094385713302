const config = {
     //Local
    // Captcha_Key: '6LfjHygTAAAAAO1YoxqluAtCCqvESw8ugpTbnNpw',
     //Production
    Captcha_Key: '6Ld4miEgAAAAAJR7RNVW71WjQ66Vw1b8zb4sfTRS',
    footerFilterId: '',
    loginCheckId: 'login-first',
    campaignPageHeader: 'no',
    ngoPageHeader: 'no',
    faqPageHeader: 'no',
    loginPageHeader: 'no',
    signupPageHeader: 'no',

    //UAT
    APP_URL: '/',
    ebpgCallbackURL: '/payment/callback/card',
    ebpgcardsURL: 'https://ebpg.maybank.com/BPG/admin/payment/PaymentWindow.jsp',

    // SOCIAL NETWORK
    FB_ID: '503460701535750',
    GOOGLE_ID: '153474509811-2t3gjeh1ndr2qnefmru10d959vagvtv3.apps.googleusercontent.com',
    GOOGLE_ANALYTICS_TRACKING_ID: 'G-TF0F2ET7M8',
    ...process.env,
};

export default config;







