
import React from "react";
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import { Progress } from "reactstrap";
import '../../assets/css/style.css';
// import config from '../../config/config';
import leftScroll from '../../assets/img/campaign/left-scroll.svg';
import dotScroll from '../../assets/img/campaign/dot-scroll.svg';
import rectScroll from '../../assets/img/campaign/rect-scroll.svg';
import rightScroll from '../../assets/img/campaign/right-scroll.svg';
import TrendButtons from '../../components/Buttons/TrendButtons';

class HomeTrendingSection extends React.Component {
  constructor(props) {
    super(props)
    this.scroll = this.scroll.bind(this);
    //this.go2SupportPage = this.go2SupportPage.bind(this);
    this.numberWithCommas = this.numberWithCommas.bind(this);
    this.state = {
      trendingCampaigns: [],
    }
  }
  componentDidMount() {
          axios.get("api/Campaign?labels=Trending&status=Active" , {
            headers: {
                "Content-Type": "application/json",
                'Authorization': ''
            },
        }).then(res => {
            const trendingCampaigns = res.data.data;
            //console.log(JSON.stringify(trendingCampaigns));
            this.setState({ trendingCampaigns });
        }).catch(e => console.log(e))
  }
  go2SupportPage(camp) {
    this.props.history.push({
      pathname: '/support-page',
      campaignData: camp
    })
  };
  numberWithCommas(x = 0) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  campaignDetails(camp){
     this.props.history.push({
      pathname: '/campaign-details-page',
      search: 'Campaign='+ camp.id,
      data: camp
    })
  }


  scroll(direction) {
    let far = $('.image-container-trending').width() * direction;
    let pos = $('.image-container-trending').scrollLeft() + far;
    $('.image-container-trending').animate({ scrollLeft: pos }, 1000)
  }

  render() {
    return <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="wrapper" style={{ background: '#F0F0F0' }}>
            <div className="image-container-trending">
              {this.state.trendingCampaigns.map((camp) => {
                return <div className="image-slider" key={camp.id} >
                  <div className="card-trend-home">
                    <div onClick={this.campaignDetails.bind(this,camp)} ><img src={camp.image} className="camp-slider-image" alt="campaign" /></div>
                    <div className="trend-data">
                      <div className="camp-category">{camp.category}</div>
                      <div className="trend-home-title"> {(camp.title).slice(0, 60)} { camp.title.length > 60 ? ("..") : null }
                   </div>
                      <div className="camp-loc">{camp.state}, {camp.country}</div>
                      <div className="tax-label-div">
                        {camp.labels[0] !== undefined ? (
                         <span className="tax-exempt-label"> {camp.labels[0]}</span>
                        ) : null}
                          {camp.labels[1] !== undefined ? (
                        <span className="tax-exempt-label">{camp.labels[1]}</span>
                        ) : null}
                      </div>
                      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <div className="camp-loc"><span className="camp-amount">{this.numberWithCommas(camp.pledgeAmtFull)}</span><span className="camp-loc-label">MYR Raised</span></div>
                        <div className="progress-div"><Progress  max="100" value={camp.progress} barClassName="progress-bar-success" /></div>
                        <div><span className="camp-loc-percentage">{Math.round(camp.progress)}<span> %</span> of {this.numberWithCommas(camp.pledgeAmt)} MYR</span>
                        <span className="camp-loc-percentage" style={{ float: 'right' }}>{
                          camp.daysLeft > 10 ? <span>{camp.daysLeft}  days left</span> : <span style={{color:'#F03E3E'}}>{camp.daysLeft}  days left</span>
                        }</span>
                        </div>
                      </div>
                      <TrendButtons campaign={camp} handleSupport={this.go2SupportPage.bind(this, camp)} />
                    </div>
                  </div>
                </div>
              })}
            </div>
            <div style={{ textAlign: 'center', paddingTop: '30px' }}>
              <img src={leftScroll} onClick={this.scroll.bind(null, -1)} className="scroll-arrow" alt="left-scroll" />
              <img src={dotScroll} className="padleftright10" alt="dot-scroll" />
              <img src={dotScroll} className="padleftright10" alt="dot-scroll" />
              <img src={rectScroll} className="padleftright10" alt="rect-scroll" />
              <img src={dotScroll} className="padleftright10" alt="dot-scroll" />
              <img src={dotScroll} className="padleftright10" alt="dot-scroll" />
              <img src={rightScroll} onClick={this.scroll.bind(null, 1)} className="scroll-arrow" alt="right-scroll" />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
export default withRouter(HomeTrendingSection);
