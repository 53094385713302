
import React from "react";
import axios from 'axios';
import $ from 'jquery';
import Loader from "react-loader-spinner";
import { Progress } from "reactstrap";
import { withRouter } from 'react-router-dom';
import '../../assets/css/style.css';
import leftscroll from '../../assets/img/campaign/left-scroll.svg';
import dotscroll from '../../assets/img/campaign/dot-scroll.svg';
import rectscroll from '../../assets/img/campaign/rect-scroll.svg';
import rightscroll from '../../assets/img/campaign/right-scroll.svg';
// import shareIcon from '../../assets/img/campaign/share-icon.svg';
// import supportIconWhite from '../../assets/img/campaign/support-icon-white.svg';
import TrendButtons from '../../components/Buttons/TrendButtons';

class HealthyCategory extends React.Component {
  constructor(props) {
    super(props)
    this.scroll = this.scroll.bind(this);
    this.numberWithCommas = this.numberWithCommas.bind(this);
    this.state = {
      healthyCampaigns: [],
      loading: false,
      loadingDiv: 'none',
    }
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.setState({ loadingDiv: 'block' });
    // axios.get(config.APP_URL + "api/Campaign?category=Healthy+Living" , {
    axios.get("api/Campaign?category=Healthy+Living&status[]=Active&status[]=Completed", {
      headers: {
        "Content-Type": "application/json",
        'Authorization': ''
      },
    }).then(res => {
      const healthyCampaigns = res.data.data;
      this.setState({ loading: false });
      this.setState({ loadingDiv: 'none' });
      this.setState({ healthyCampaigns });
    }).catch(e => console.log(e))
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  campaignDetails(camp) {
    this.props.history.push({
      pathname: '/campaign-details-page',
      search: 'Campaign='+ camp.id,
      data: camp
    })
  }
  go2SupportPage(camp) {
    this.props.history.push({
      pathname: '/support-page',
      campaignData: camp
    })
  };
  scroll(direction) {
    let far = $('.image-container').width() * direction;
    let pos = $('.image-container').scrollLeft() + far;
    $('.image-container').animate({ scrollLeft: pos }, 1000)
  }

  render() {

    return <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="wrapper" style={{ background: '#F0F0F0' }}>
            <div style={{ textAlign: 'center', display: this.state.loadingDiv }}>
              <Loader
                type="Rings"
                color="#FDC83E"
                height={100}
                width={100}
                visible={this.state.loading}
              />
            </div>
            <div className="image-container-trending">
              {this.state.healthyCampaigns.map((camp) => {
                return <div className="image-slider" key={camp.id}>
                  <div className="card-camp-home">
                    <div onClick={this.campaignDetails.bind(this, camp)}><img src={camp.image} className="camp-slider-image" alt="campaign" /></div>
                    <div className="trend-data">
                      <div className="camp-category">{camp.category}</div>
                      <div className="trend-home-title"> {(camp.title).slice(0, 60)} { camp.title.length > 60 ? ("..") : null }
                   </div>
                      <div className="camp-loc">{camp.state}, {camp.country}</div>
                      <div className="tax-label-div">
                        {camp.labels[0] !== undefined ? (
                          <span className="tax-exempt-label"> {camp.labels[0]}</span>
                        ) : null}
                        {camp.labels[1] !== undefined ? (
                          <span className="tax-exempt-label">{camp.labels[1]}</span>
                        ) : null}
                      </div>
                      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <div className="camp-loc"><span className="camp-amount">{this.numberWithCommas(camp.pledgeAmtFull)}</span><span className="camp-loc-label">MYR Raised</span></div>
                        <div className="progress-div"><Progress max="100" value={camp.progress} barClassName="progress-bar-success" /></div>
                        <div><span className="camp-loc-percentage">{Math.round(camp.progress)}% of {this.numberWithCommas(camp.pledgeAmt)} MYR</span>
                        <span className="camp-loc-percentage" style={{ float: 'right' }}>{
                          camp.daysLeft > 10 ? <span>{camp.daysLeft}  days left</span> : <span style={{color:'#F03E3E'}}>{camp.daysLeft}  days left</span>
                        }</span>
                        </div>
                      </div>
                      <TrendButtons campaign={camp} handleSupport={this.go2SupportPage.bind(this, camp)} />
                    </div>
                  </div>
                </div>
              })}
            </div>
            <div style={{ textAlign: 'center', paddingTop: '30px' }}>
              <img src={leftscroll} onClick={this.scroll.bind(null, -1)} className="scroll-arrow" alt="left-scroll" />
              <img src={dotscroll} className="padleftright10" alt="dot-scroll" />
              <img src={dotscroll} className="padleftright10" alt="dot-scroll" />
              <img src={rectscroll} className="padleftright10" alt="rect-scroll" />
              <img src={dotscroll} className="padleftright10" alt="dot-scroll" />
              <img src={dotscroll} className="padleftright10" alt="dot-scroll" />
              <img src={rightscroll} onClick={this.scroll.bind(null, 1)} className="scroll-arrow" alt="right-scroll" />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
export default withRouter(HealthyCategory);
