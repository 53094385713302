
import React from "react";
import axios from 'axios';
import ReactGA from 'react-ga4';
// import { Card } from "reactstrap";
import '../../assets/css/style.css';
import config from '../../config/config';
import backArrow from '../../assets/img/campaign/back-arrow.svg';

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader";
import DemoFooter from "components/Footers/DemoFooter.js";


class NgoPage extends React.Component {
    constructor(props) {
        super(props)
        this.go2HomePage = this.go2HomePage.bind(this);
        this.state = {
            ngoArr: [],
          }
    }
    go2HomePage(){
        this.props.history.push('/');
    }
    componentDidMount() {
        ReactGA.event({
            category: "User",
            action: "Viewed NGOs",
        });
        config.ngoPageHeader = 'yes';
        window.scrollTo(0, 0);
         //axios.get(config.APP_URL + "api/NGO" , {
            axios.get("api/NGO" , {
          headers: {
              "Content-Type": "application/json",
              'Authorization': ''
          },
      }).then(res => {
          const ngoArr = res.data.data;
          //console.log(JSON.stringify(ngoArr));
          this.setState({ ngoArr });
      }).catch(e => console.log(e))
    }
    render() {
        return <div style={{ background: '#F0F0F0' }}>
            <ExamplesNavbar />
            <LandingPageHeader />
            <div className="detailBanner">
            <div className="container ngoandcommunitydiv">
                <div>
                <button className="back-btn"  onClick={this.go2HomePage}><img src={backArrow}  style={{ width: '13px'}} alt="back button" />BACK </button>
                    </div>
                <div className="ngo-and-community-txt">NGOs With Us</div>
            </div>
            </div>
            <div className="container" style={{ paddingTop: '50px', paddingBottom: '40px' }}>
            <div className="row">
                {this.state.ngoArr.map((ngo) => {
                  return <div className="col-md-3" key={ngo.id}>
                        <div className="ngocard">
                            <img src={ngo.logo} className="ngoImgeSize" alt="ngo img" />
                        </div>
                    </div>
                   })}
                </div>
            </div>
            <DemoFooter />

        </div>;
    }
}

export default NgoPage;
