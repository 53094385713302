import React from "react";
import axios from 'axios';
import Select from 'react-select';
import { Button, Progress } from "reactstrap";
import config from '../../config/config';
import Loader from "react-loader-spinner";
import { withRouter } from 'react-router-dom';

import TrendButtons from '../../components/Buttons/TrendButtons';

const selectStyles = {
    option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? "#FFF" : styles.color,
        backgroundColor: state.isSelected ? "#fff" : styles.color,
        cursor: state.isDisabled ? 'pointer' : 'default',
        borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
        "&:hover": {
            color: "#000",
            backgroundColor: "#F0F0F0"
        }
    }),
    control: (styles, state) => ({
        ...styles,
        boxShadow: state.isFocused ? "#ddd" : 0,
        borderColor: state.isFocused ? "#000" : "#CED4DA",
        "&:hover": {
            borderColor: state.isFocused ? "#000" : "#CED4DA"
        }
    })
};
class CampaignsHealthyCategory extends React.Component {
    constructor() {
        super()
        this.loadMoreEduCamps = this.loadMoreEduCamps.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.numberWithCommas = this.numberWithCommas.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
            educationCampaignsTotal: [],
            educationCampaignsSet1: [],
            selectedFilterId: 'Select',
            selectedFilterName: 'Select',
            loading: false,
            loadingDiv: 'none',
            eduCampLess: 'true',
            eduCampMore: 'false',
            loadMoreBtn: 'false',
            searchText: '',
        }
    }
    componentDidMount() {
        this.setState({ loading: true });
        this.setState({ loadingDiv: 'block' });

        const campaignFilter = [
            {
                "filterId": "Trending",
                "filterName": "Trending"
            },
            {
                "filterId": "Ending+Soon",
                "filterName": "Ending Soon"
            },
             {
                "filterId": "Completed",
                "filterName": "Completed"
            }
        ];

        this.setState({ campaignFilterArr: campaignFilter });

        if(config.footerFilterId === ''){
            this.getAllCampaigns();
        }else {
            this.getFilteredCampaigns(config.footerFilterId);
            this.setState({selectedFilterName: config.footerFilterId});
        }
    }
    getAllCampaigns(){
        axios.get("api/Campaign?category=Healthy+Living&status[]=Active&status[]=Completed", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': ''
            },
        }).then(res => {
            const educationCampaignsTotal = res.data.data;
            const educationCampaignsSet1 = educationCampaignsTotal.slice(0, 6);
            if (educationCampaignsTotal.length > 6) {
                this.setState({ loadMoreBtn: 'true' });
            }
            this.setState({ loading: false });
            this.setState({ loadingDiv: 'none' });
            this.setState({ educationCampaignsTotal });
            this.setState({ educationCampaignsSet1 });
        }).catch(e => console.log(e))
    }
    handleFilterChange(event) {
        this.setState({ selectedFilterId: event.filterId });
        this.setState({ selectedFilterName: event.filterName });

        this.getFilteredCampaigns(event.filterId);
    }
    getFilteredCampaigns(filterId) {
        this.setState({ loading: true });
        this.setState({ loadingDiv: 'block' });

        //Education Campaigns Filter
        if (filterId === 'Trending') {
            axios.get("api/Campaign?labels=Trending&category=Healthy+Living&status[]=Active&status[]=Completed", {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': ''
                },
            }).then(res => {
                const educationCampaignsTotal = res.data.data;
                const educationCampaignsSet1 = educationCampaignsTotal.slice(0, 6);
                if (educationCampaignsTotal.length > 6) {
                    this.setState({ loadMoreBtn: 'true' });
                }
                this.setState({ loading: false });
                this.setState({ loadingDiv: 'none' });
                this.setState({ educationCampaignsTotal });
                this.setState({ educationCampaignsSet1 });
            }).catch(e => console.log(e))
        } else if (filterId === 'Completed') {
            axios.get("api/Campaign?status=Completed&category=Healthy+Living&status[]=Active&status[]=Completed", {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': ''
                },
            }).then(res => {
                const educationCampaignsTotal = res.data.data;
                const educationCampaignsSet1 = educationCampaignsTotal.slice(0, 6);
                if (educationCampaignsTotal.length > 6) {
                    this.setState({ loadMoreBtn: 'true' });
                }
                this.setState({ loading: false });
                this.setState({ loadingDiv: 'none' });
                this.setState({ educationCampaignsTotal });
                this.setState({ educationCampaignsSet1 });
            }).catch(e => console.log(e))
        } else {
            axios.get("api/Campaign?labels=" + filterId + "&category=Healthy+Living&status[]=Active&status[]=Completed", {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': ''
                },
            }).then(res => {
                const educationCampaignsTotal = res.data.data;
                const educationCampaignsSet1 = educationCampaignsTotal.slice(0, 6);
                if (educationCampaignsTotal.length > 6) {
                    this.setState({ loadMoreBtn: 'true' });
                }
                this.setState({ loading: false });
                this.setState({ loadingDiv: 'none' });
                this.setState({ educationCampaignsTotal });
                this.setState({ educationCampaignsSet1 });
            }).catch(e => console.log(e))

        }
    }
    go2SupportPage(camp) {
        this.props.history.push({
          pathname: '/support-page',
          campaignData: camp
        })
      };
    loadMoreEduCamps() {
        this.setState({ eduCampLess: 'false' });
        this.setState({ eduCampMore: 'true' });
        this.setState({ searchText: '' });
    };
    handleSearch(e) {
        this.setState({ searchText: e.target.value });
        const keyword = e.target.value;
            if (this.state.eduCampMore === 'true') {
                const educationCampaignsTotal = this.state.educationCampaignsTotal.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes(keyword)));
                this.setState({ educationCampaignsTotal });
            } else if(this.state.eduCampLess === 'true') {
                const educationCampaignsSet1 = (this.state.educationCampaignsTotal.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes(keyword)))).slice(0, 6);
                this.setState({ educationCampaignsSet1 });
            }
    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    campaignDetails(camp) {
        this.props.history.push({
            pathname: '/campaign-details-page',
            search: 'Campaign='+ camp.id,
            data: camp
        })
    }
    render() {
        const eduCampLess = this.state.eduCampLess;
        const eduCampMore = this.state.eduCampMore;
        const loadMoreBtn = this.state.loadMoreBtn;

        let showEduCampsLess;
        let showEduCampsMore;
        let showLoadMoreBtn;

        if (loadMoreBtn === 'true') {
            showLoadMoreBtn = <div> <Button className="btn-round loadmorebtn" color="default" type="button" onClick={this.loadMoreEduCamps}>
                Load More
      </Button></div>
        }
        if (eduCampLess === 'true') {
            if(this.state.educationCampaignsSet1.length > 0){
            showEduCampsLess = <div><div className="row">
                {this.state.educationCampaignsSet1.map((camp) => {
                    return <div className="col-md-4" style={{ marginBottom: '30px' }}>
                        <div className="card-trend">
                            <div onClick={this.campaignDetails.bind(this, camp)} ><img src={camp.image} className="camp-slider-image" alt="campaign" /></div>
                            <div className="trend-data">
                                <div className="camp-category">{camp.category}</div>
                                <div className="trend-home-title"> {(camp.title).slice(0, 60)} { camp.title.length > 60 ? ("..") : null }
                   </div>
                                <div className="camp-loc">{camp.state}, {camp.country}</div>
                                <div className="tax-label-div">
                                    {camp.labels[0] !== undefined ? (
                                        <span className="tax-exempt-label"> {camp.labels[0]}</span>
                                    ) : null}
                                    {camp.labels[1] !== undefined ? (
                                        <span className="tax-exempt-label">{camp.labels[1]}</span>
                                    ) : null}
                                </div>
                                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <div className="camp-loc"><span className="camp-amount">{this.numberWithCommas(camp.pledgeAmtFull)}</span><span className="camp-loc-label">MYR Raised</span></div>
                                    <div className="progress-div"><Progress max="100" value={camp.progress} barClassName="progress-bar-success" /></div>
                                    <div><span className="camp-loc-percentage">{Math.round(camp.progress)} % of {this.numberWithCommas(camp.pledgeAmt)} MYR</span><span className="camp-loc-percentage" style={{ float: 'right' }}>{camp.daysLeft} days left</span></div>
                                </div>
                                <TrendButtons campaign={camp} handleSupport={this.go2SupportPage.bind(this, camp)} />
                            </div>
                        </div>
                    </div>
                })}
            </div>
                {showLoadMoreBtn}
            </div>
              }else {
                showEduCampsLess = <div className="row"><div className="col-md-12"><span>No Campaigns Found!</span></div></div>
            }
        }
        if (eduCampMore === 'true') {
            showEduCampsMore = <div><div className="row">
                {this.state.educationCampaignsTotal.map((camp) => {
                    return <div className="col-md-4" style={{ marginBottom: '30px' }}>
                        <div className="card-trend">
                            <div onClick={this.campaignDetails.bind(this, camp)} ><img src={camp.image} className="camp-slider-image" alt="campaign" /></div>
                            <div className="trend-data">
                                <div className="camp-category">{camp.category}</div>
                                <div className="trend-home-title"> {(camp.title).slice(0, 60)} { camp.title.length > 60 ? ("..") : null }
                   </div>
                                <div className="camp-loc">{camp.state}, {camp.country}</div>
                                <div className="tax-label-div">
                                    {camp.labels[0] !== undefined ? (
                                        <span className="tax-exempt-label"> {camp.labels[0]}</span>
                                    ) : null}
                                    {camp.labels[1] !== undefined ? (
                                        <span className="tax-exempt-label">{camp.labels[1]}</span>
                                    ) : null}
                                </div>
                                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <div className="camp-loc"><span className="camp-amount">{camp.pledgeAmtFull}</span><span className="camp-loc-label">MYR Raised</span></div>
                                    <div className="progress-div"><Progress max="100" value={camp.progress} barClassName="progress-bar-success" /></div>
                                    <div><span className="camp-loc-percentage">{Math.round(camp.progress)}% of {camp.pledgeAmt} MYR</span><span className="camp-loc-percentage" style={{ float: 'right' }}>{camp.daysLeft} days left</span></div>
                                </div>
                                <TrendButtons campaign={camp} handleSupport={this.go2SupportPage.bind(this, camp)} />
                            </div>
                        </div>
                    </div>
                })}
            </div>
            </div>
        }
        return (
            <div>
                <div className="row" style={{ marginBottom: '20px' }}>
                    <div className="col-md-3 marg-bot-10-mobile">
                    <input className="searchCamp" type="text" value={this.state.searchText} onChange={this.handleSearch} placeholder="&#xF002;  Search Campaign" />
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <Select
                            styles={selectStyles}
                            getOptionValue={option => option.filterId}
                            getOptionLabel={option => option.filterName}
                            onChange={this.handleFilterChange}
                            options={this.state.campaignFilterArr}
                            value={this.state.selectedFilterId}
                            placeholder={this.state.selectedFilterName}
                        />
                    </div>
                </div>
                <div style={{ textAlign: 'center', display: this.state.loadingDiv }}>
                    <Loader
                        type="Rings"
                        color="#FDC83E"
                        height={100}
                        width={100}
                        visible={this.state.loading}
                    />
                </div>
                {showEduCampsLess}
                {showEduCampsMore}
            </div>
        )
    }
}
export default withRouter(CampaignsHealthyCategory);
