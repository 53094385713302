import { createStore } from "redux";
//import userReducer from "reducers/userReducer";

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//import reducer from './reducers/index'

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'campaign']
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer);
 
export default () => {
  let store = createStore(persistedReducer)
  let persistor = persistStore(store)
  return { store, persistor }
}

// function configureStore(state = { user_data: {} }) {
//   return createStore(userReducer,state);
// }

// export default configureStore;




