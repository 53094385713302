import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';

import "assets/css/bootstrap.min.css";
import "assets/css/font-awesome.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";

// pages
import Index from "views/Index.js";
import LoginPage from "views/examples/LoginPage";
import RegisterPage from "views/examples/RegisterPage.js";
import ForgotPassword from "views/examples/ForgotPassword.js";
import CampaignsPage from "views/examples/CampaignsPage";
import CampaignDetailsPage from "views/examples/CampaignDetails";
import SupportPage from "views/examples/SupportPage";
import PaymentResultPage from "views/examples/PaymentResult";
import NgoPage from "views/examples/NgoPage";
import FaqPage from "views/examples/FaqPage";
import { connect } from "react-redux";
import ReactGA from 'react-ga4';

import config from '../src/config/config';

const {GOOGLE_ANALYTICS_TRACKING_ID = ''} = config;

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

class App extends Component {
  render() {
    return (      
       <BrowserRouter>
        <div>        
            <Switch>           
             <Route path="/" component={Index} exact/>
             <Route path="/login-page" component={LoginPage}/>
             <Route path="/register-page" component={RegisterPage}/>
             <Route path="/forgot-password" component={ForgotPassword}/>
             <Route path="/campaigns-page" component={CampaignsPage}/>
             <Route path="/campaign-details-page" component={CampaignDetailsPage}/>
             <Route path="/support-page" component={SupportPage}/>
             <Route path="/payment-result" component={PaymentResultPage}/>
             <Route path="/ngo-page" component={NgoPage}/>
             <Route path="/faq-page" component={FaqPage}/>
           </Switch>
        </div> 
      </BrowserRouter>
    );
  }
}
 
export default connect()(App);