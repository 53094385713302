import React from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import {Button, Progress} from "reactstrap";
import config from "../../config/config";
import "../../assets/css/style.css";
import CampaignsAllCategory from "views/index-sections/CampaignsAllCategory";
import CampaignsEducationCategory
  from "views/index-sections/CampaignsEducationCategory";
import CampaignsDisasterCategory
  from "views/index-sections/CampaignsDisasterCategory";
import CampaignsCommunityCategory
  from "views/index-sections/CampaignsCommunityCategory";
import CampaingsArtsCategory from "views/index-sections/CampaingsArtsCategory";
import CampaignsHealthyCategory
  from "views/index-sections/CampaignsHealthyCategory";
import CampaignsEnvironmentCategory
  from "views/index-sections/CampaignsEnvironmentCategory";

import LandingPageHeader from "../../components/Headers/LandingPageHeader";
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar";
import DemoFooter from "../../components/Footers/DemoFooter";

// import shareIcon from '../../assets/img/campaign/share-icon.svg';
// import supportIconWhite from '../../assets/img/campaign/support-icon-white.svg';
import eduIcon from "../../assets/img/campaign/cat-edu.svg";
import allIcon from "../../assets/img/campaign/cat-all.svg";
import commIcon from "../../assets/img/campaign/cat-commu.svg";
import disaIcon from "../../assets/img/campaign/cat-disa.svg";
import artIcon from "../../assets/img/campaign/cat-art.svg";
import healthyIcon from "../../assets/img/campaign/cat-healthy.svg";
import enviIcon from "../../assets/img/campaign/cat-envi.svg";
import topScroll from "../../assets/img/campaign/top-arrow.svg";

import Tabs from "../../components/Tabs/Tabs";
import TrendButtons from "../../components/Buttons/TrendButtons";

class CampaignPage extends React.Component {
  constructor() {
    super();
    this.loadMoreEduCamps = this.loadMoreEduCamps.bind(this);
    this.numberWithCommas = this.numberWithCommas.bind(this);
    this.state = {
      trendingCampaignsTotal: [],
      trendingCampaignsSet1: [],
      loading: false,
      is_visible: false,
      loadingDiv: "none",
      trendCampLess: "true",
      trendCampMore: "false",
      loadMoreBtn: "false",
    };

  }

  componentDidMount() {
    config.campaignPageHeader = "yes";
    window.scrollTo(0, 0);
    this.getAllCampaigns();

    const scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
    this.setState({loading: true});
    this.setState({loadingDiv: "block"});
  }

  getAllCampaigns() {
    ReactGA.event({
      category: "User",
      action: "Viewed all campaigns",
    });
    axios.get("api/Campaign?labels=Trending&status=Active", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "",
      },
    }).then(res => {
      const trendingCampaignsTotal = res.data.data;
      const trendingCampaignsSet1 = trendingCampaignsTotal.slice(0, 6);
      if (trendingCampaignsTotal.length > 6) {
        this.setState({loadMoreBtn: "true"});
      }
      this.setState({trendingCampaignsTotal});
      this.setState({trendingCampaignsSet1});
    }).catch(e => console.log(e));
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  go2SupportPage(camp) {
    this.props.history.push({
      pathname: "/support-page",
      campaignData: camp,
    });
  };

  loadMoreEduCamps() {
    this.setState({trendCampLess: "false"});
    this.setState({trendCampMore: "true"});
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  campaignDetails(camp) {
    this.props.history.push({
      pathname: "/campaign-details-page",
      search: "Campaign=" + camp.id,
      data: camp,
    });
  }

  render() {
    const {is_visible} = this.state;
    const trendCampLess = this.state.trendCampLess;
    const trendCampMore = this.state.trendCampMore;
    const loadMoreBtn = this.state.loadMoreBtn;

    let showTrendCampsLess;
    let showTrendCampsMore;
    let showLoadMoreBtn;
    if (loadMoreBtn === "true") {
      showLoadMoreBtn = <div style={{textAlign: "center"}}><Button
          className="btn-round loadmorebtn" color="default" type="button"
          onClick={this.loadMoreEduCamps}>
        Load More
      </Button></div>;
    }
    if (trendCampLess === "true") {
      showTrendCampsLess = <div>
        <div className="row">
          {this.state.trendingCampaignsSet1.map((camp) => {
            return <div className="col-md-4 col-xs-12"
                        style={{marginBottom: "30px"}} key={camp.id}>
              <div className="card-trend">
                <div onClick={this.campaignDetails.bind(this, camp)}><img
                    src={camp.image} className="camp-slider-image"
                    alt="campaign"/></div>
                <div className="trend-data">
                  <div className="camp-category">{camp.category}</div>
                  <div className="trend-home-title"> {(camp.title).slice(0,
                      60)} {camp.title.length > 60 ? ("..") : null}
                  </div>
                  <div className="camp-loc">{camp.state}, {camp.country}</div>
                  <div className="tax-label-div">
                    {camp.labels[0] !== undefined ? (
                        <span
                            className="tax-exempt-label"> {camp.labels[0]}</span>
                    ) : null}
                    {camp.labels[1] !== undefined ? (
                        <span
                            className="tax-exempt-label">{camp.labels[1]}</span>
                    ) : null}
                  </div>
                  <div style={{marginTop: "20px", marginBottom: "20px"}}>
                    <div className="camp-loc"><span
                        className="camp-amount">{this.numberWithCommas(
                        camp.pledgeAmtFull)}</span><span
                        className="camp-loc-label">MYR Raised</span></div>
                    <div className="progress-div"><Progress max="100"
                                                            value={camp.progress}
                                                            barClassName="progress-bar-success"/>
                    </div>
                    <div><span className="camp-loc-percentage">{Math.round(
                        camp.progress)}% of {this.numberWithCommas(
                        camp.pledgeAmt)} MYR</span>
                      <span className="camp-loc-percentage"
                            style={{float: "right"}}>{
                        camp.daysLeft > 10 ?
                            <span>{camp.daysLeft} days left</span> :
                            <span
                                style={{color: "#F03E3E"}}>{camp.daysLeft} days left</span>
                      }</span>
                    </div>
                  </div>
                  <TrendButtons campaign={camp}
                                handleSupport={this.go2SupportPage.bind(this,
                                    camp)}/>
                </div>
              </div>
            </div>;
          })}
        </div>
        {showLoadMoreBtn}
      </div>;
    }
    if (trendCampMore === "true") {
      showTrendCampsMore = <div>
        <div className="row">
          {this.state.trendingCampaignsTotal.map((camp) => {
            return <div className="col-md-4" style={{marginBottom: "30px"}}>
              <div className="card-trend">
                <div onClick={this.campaignDetails.bind(this, camp)}><img
                    src={camp.image} className="camp-slider-image"
                    alt="campaign"/></div>
                <div className="trend-data">
                  <div className="camp-category">{camp.category}</div>
                  <div className="trend-home-title"> {(camp.title).slice(0,
                      60)} {camp.title.length > 60 ? ("..") : null}
                  </div>
                  <div className="camp-loc">{camp.state}, {camp.country}</div>
                  <div className="tax-label-div">
                    {camp.labels[0] !== undefined ? (
                        <span
                            className="tax-exempt-label"> {camp.labels[0]}</span>
                    ) : null}
                    {camp.labels[1] !== undefined ? (
                        <span
                            className="tax-exempt-label">{camp.labels[1]}</span>
                    ) : null}
                  </div>
                  <div style={{marginTop: "20px", marginBottom: "20px"}}>
                    <div className="camp-loc"><span
                        className="camp-amount">{this.numberWithCommas(
                        camp.pledgeAmtFull)}</span><span
                        className="camp-loc-label">MYR Raised</span></div>
                    <div className="progress-div"><Progress max="100"
                                                            value={camp.progress}
                                                            barClassName="progress-bar-success"/>
                    </div>
                    <div><span className="camp-loc-percentage">{Math.round(
                        camp.progress)}% of {this.numberWithCommas(
                        camp.pledgeAmt)} MYR</span><span
                        className="camp-loc-percentage"
                        style={{float: "right"}}>{camp.daysLeft} days left</span>
                    </div>
                  </div>
                  <TrendButtons campaign={camp}
                                handleSupport={this.go2SupportPage.bind(this,
                                    camp)}/>
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>;
    }
    return (
        <div style={{background: "rgb(240, 240, 240)"}}>
          <ExamplesNavbar/>
          <LandingPageHeader/>
          <div className="container">
            <div className="row" style={{padding: "20px"}}>
              <div className="col-md-12 col-sm-12 col-xs-12"
                   style={{textAlign: "center"}}><span
                  className="section-heading">Trending Campaigns</span></div>
            </div>
          </div>
          <div className="container">
            {showTrendCampsLess}
            {showTrendCampsMore}
          </div>

          <div className="container" style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "0px",
            textAlign: "center",
          }}>
            <Tabs>
              <div style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={allIcon} alt="Education Campaign"
                          className="cat-img"/></div>
                <div className="home-cate-title">All</div>
                <div className="home-cate-title">Campaigns</div>
              </div>
              <span>  {
                <div style={{marginLeft: "15px", marginRight: "15px"}}>
                  <CampaignsAllCategory/>
                </div>
              }
            </span>
              <div style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={eduIcon} alt="Education Campaign"
                          className="cat-img"/></div>
                <div className="home-cate-title">Education</div>
                <div className="home-cate-title" style={{color: "#F0F0F0"}}>.
                </div>
              </div>
              <span>  {
                <div style={{marginLeft: "15px", marginRight: "15px"}}>
                  <CampaignsEducationCategory/>
                </div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={commIcon} alt="Community Empowderment"
                          className="cat-img"/></div>
                <div className="home-cate-title">Community</div>
                <div className="home-cate-title">Empowerment</div>
              </div>
              <span>
              {
                <div style={{marginLeft: "15px", marginRight: "15px"}}>
                  <CampaignsCommunityCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={disaIcon} alt="Disaster Relief"
                          className="cat-img"/></div>
                <div className="home-cate-title"> Disaster</div>
                <div className="home-cate-title">Relief</div>
              </div>
              <span>
              {
                <div style={{marginLeft: "15px", marginRight: "15px"}}>
                  <CampaignsDisasterCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={artIcon} alt="Art Culture" className="cat-img"/>
                </div>
                <div className="home-cate-title"> Arts &</div>
                <div className="home-cate-title">Culture</div>
              </div>
              <span>
              {
                <div style={{marginLeft: "15px", marginRight: "15px"}}>
                  <CampaingsArtsCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={healthyIcon} alt="Healthy Living"
                          className="cat-img"/></div>
                <div className="home-cate-title"> Healthy</div>
                <div className="home-cate-title">Living</div>
              </div>
              <span>
              {
                <div style={{marginLeft: "15px", marginRight: "15px"}}>
                  <CampaignsHealthyCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={enviIcon} alt="Environment Diversity"
                          className="cat-img"/></div>
                <div className="home-cate-title"> Environmental</div>
                <div className="home-cate-title">Diversity</div>
              </div>
              <span>
              {
                <div style={{marginLeft: "15px", marginRight: "15px"}}>
                  <CampaignsEnvironmentCategory/></div>
              }
            </span>
            </Tabs>
          </div>
          <DemoFooter/>
          <div>
            {is_visible &&
                <div onClick={() => this.scrollToTop()}>
                  <img src={topScroll} style={{width: "40px"}} alt="top-scroll"
                       className="scrolltotop"/>
                </div>}
          </div>
        </div>
    );
  }
}

export default CampaignPage;
