import React from 'react';
import axios from 'axios';
import ReactGA from 'react-ga4';
import {Button} from 'reactstrap';
import backArrow from '../../assets/img/campaign/back-arrow.svg';
import defaultBanner from '../../assets/img/banners/defaultBanner.jpg';
import paymentSuccess from '../../assets/img/campaign/payment-success.svg';
import paymentFailed from '../../assets/img/campaign/payment-failed.svg';
import close from '../../assets/img/close.svg';
import tryAgainIcon from '../../assets/img/campaign/try-again.svg';

import config from '../../config/config';

import ExamplesNavbar from 'components/Navbars/ExamplesNavbar.js';
import LandingPageHeader from '../../components/Headers/LandingPageHeader';
import TrendButtons from '../../components/Buttons/TrendButtons';

class PaymentResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.go2HomePage = this.go2HomePage.bind(this);
    this.go2SupportPage = this.go2SupportPage.bind(this);
    this.GET = {};
    this.state = {
      campBanner: '',
      modalType: '',
      paymentDate: '',
      campTitle: '',
      displayName: 'User',
      m2uResult: props.location.m2uPaymentResult,
      campId: '',
    };
  }

  componentDidMount() {
    this.setState({campBanner: defaultBanner});

    this.loginCheck();

    if (window.location.search.length) {
      const GET = {};
      window.location.search.substr(1).split('&').forEach((pairs) => {
        const pair = pairs.split('=');
        GET[pair[0]] = pair[1];
      });

      const replacedDate = decodeURIComponent(GET['date']);
      const replacedTitle = decodeURIComponent(GET['title']);
      this.setState({
        paymentDate: replacedDate,
        campTitle: replacedTitle,
        campId: GET['id'],
      });

      ReactGA.event({
        category: "User",
        action: "Donation " + GET['status'],
        label: GET['id']
      });
      if (GET['status'] === 'SUCCESS') {
        this.setState({modalType: 'success'});
      } else if (GET['status'] === 'FAILURE') {
        this.setState({modalType: 'failure'});
      } else {
        this.props.history.push('/');
      }
    }
    if (this.state.m2uResult) {
      if (this.state.m2uResult.status === 'SUCCESS') {
        this.setState({modalType: 'success'});
        this.setState({paymentDate: this.state.m2uResult.date});
        this.setState({campTitle: this.state.m2uResult.title});
        this.setState({campId: this.state.m2uResult.id});
      } else if (this.state.m2uResult.status === 'FAILURE') {
        this.setState({modalType: 'failure'});
        this.setState({paymentDate: this.state.m2uResult.date});
        this.setState({campTitle: this.state.m2uResult.title});
        this.setState({campId: this.state.m2uResult.id});
      }
    }
  }
  loginCheck(){
   axios.get(config.APP_URL + 'is-login', {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(res => {
        if (res.data.status === 'success') {
          this.setState({displayName: res.data.displayName});
        } else {
          this.setState({displayName: 'User'});
        }
      }).catch(e => console.log(e));
  }
  go2HomePage() {
    this.props.history.push('/');
  };

  go2SupportPage() {
    this.props.history.push({
      pathname: '/support-page',
      campaignId: this.state.campId,
      campaignTitle: this.state.campTitle,
    });
  }

  render() {
   const modalType = this.state.modalType;
    let modalTypeDiv;
    const campaign = {
      id: this.state.campId,
      title: this.state.campTitle,
    };

    if (modalType === 'success') {
      modalTypeDiv =
          <div className="row" style={{marginLeft: '0px', marginRight: '0px'}}>
            <div className="col-md-1"/>
            <div className="col-md-10">
              <div className="paymt-result-div">
                <div style={{padding: '25px'}}><span
                    style={{float: 'right'}}>
                  <img src={close} onClick={this.go2HomePage} style={{
                    width: '18px',
                    cursor: 'pointer',
                  }} alt="ngo"/>
                </span>
                </div>
                <div>
                  <img src={paymentSuccess} className="payment-success-icon"
                       alt="ngo"/>
                </div>
                <div className="payment-modal-head1">WE THANK YOU!</div>
                <div style={{color: '#848484'}}>{this.state.paymentDate}</div>
                <div style={{
                  paddingBottom: '7px',
                  fontSize: '16px',
                  paddingTop: '20px',
                }}>Dear {this.state.displayName},
                </div>
                <div style={{
                  fontSize: '16px',
                  color: '#222222',
                }}>Your contribution to campaign
                </div>
                <div style={{
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#222222',
                }}>{this.state.campTitle}</div>
                <div style={{fontSize: '16px', color: '#222222'}}>was successful.</div>
                <div className='row' style={{marginTop: '20px'}}>
                  <div className="col-md-3"/>
                  <div className="col-md-6">
                    <TrendButtons customClass={'bottom'} hasSupport={false}
                                  campaign={campaign}/>
                  </div>
                  <div className="col-md-3"/>
                </div>
              </div>
            </div>
            <div className="col-md-2"/>
          </div>;
    } else if (modalType === 'failure') {
      modalTypeDiv =
          <div className="row" style={{marginLeft: '0px', marginRight: '0px'}}>
            <div className="col-md-1"/>
            <div className="col-md-10">
              <div className="paymt-result-div">
                <div style={{padding: '25px'}}><span
                    style={{float: 'right'}}> <img src={close}
                                                   onClick={this.go2HomePage}
                                                   style={{
                                                     width: '18px',
                                                     cursor: 'pointer',
                                                   }} alt="ngo"/></span>
                </div>
                <div>
                  <img src={paymentFailed} className="payment-success-icon"
                       alt="ngo"/>
                </div>
                <div className="payment-modal-head1">TRANSACTION ERROR!
                </div>
                <div style={{color: '#848484'}}>{this.state.paymentDate}</div>
                <div style={{
                  paddingBottom: '7px',
                  fontSize: '16px',
                  paddingTop: '20px',
                }}>
                  Dear {this.state.displayName},
                </div>
                <div style={{fontSize: '16px', color: '#222222'}}>Your
                  contribution to campaign
                </div>
                <div style={{
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#222222',
                }}>{this.state.campTitle}</div>
                <div style={{fontSize: '16px', color: '#222222'}}>was unsuccessful.</div>
                <div className='row' style={{marginTop: '20px'}}>
                  <div className="col-md-3"/>
                  <div className="col-md-6">
                    <Button className="btn-round ml-1 support-button-payment"
                            onClick={this.go2SupportPage}
                            style={{paddingRight: '50px', paddingLeft: '50px'}}
                            color="primary"
                            type="button"><img className="shareIcon"
                                               src={tryAgainIcon} style={{
                      width: '15px',
                      marginRight: '10px',
                    }} alt={`Try Again`}/>TRY AGAIN</Button>
                  </div>
                  <div className="col-md-3"/>
                </div>
              </div>
            </div>
            <div className="col-md-2"/>
          </div>;
    }
    return (
        <div style={{background: '#F0F0F0'}}>
          <ExamplesNavbar/>
          <LandingPageHeader/>
          <div>
            <img src={this.state.campBanner} alt="Campaign Banner"
                 className="camp-banner-img"/>
            <div className="container" style={{paddingTop: '30px'}}>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <button className="back-btn" onClick={this.go2HomePage}>
                      <img src={backArrow} style={{width: '13px'}}
                           alt="back button"/>&nbsp;BACK
                    </button>
                  </div>
                  <div className="ngo-and-community-txt"
                  >{this.state.campTitle}</div>
                </div>
              </div>
            </div>
          </div>
          <div>{modalTypeDiv}</div>
        </div>
    );
  }
}

export default PaymentResultPage;
