import React from 'react';
import axios from 'axios';
import facebook from '../../assets/img/facebook-icon.svg';
import SocialButton from './SocialButton';
import toastr from 'toastr';
import config from '../../config/config';

const {FB_ID} = config;

class FacebookButton extends React.Component {
  constructor(props) {
    super(props);
    this.loginSuccess = this.loginSuccess.bind(this);
  }

  async loginSuccess({_profile}) {
    const {success = () => {}} = this.props;
    const res = await axios.post('/auth/facebook', _profile);
    if (res.status === 200) {
      // Show toastr notification
      toastr.success('Login successful');

      // Trigger client login
      success();
    } else {
      toastr.error(res.data.message);
    }
  }

  render() {
    return (
        <SocialButton
            className={`login-facebook btn btn-secondary ${this.props.class}`}
            provider={'facebook'}
            appId={FB_ID}
            onLoginSuccess={this.loginSuccess}
            onLoginFailure={() => false}
        >
          <img src={facebook} className="social-icon-img-f" alt="ngo"/>
          <span className="social-icon-txt">FACEBOOK</span>
        </SocialButton>
    );
  }
}

export default FacebookButton;
