
import React from "react";
import axios from 'axios';
import ReactGA from 'react-ga4';
import FileBase64 from 'react-file-base64';
import toastr from 'toastr';
//import 'toastr/build/toastr.min.css';
import { withRouter } from 'react-router-dom';
import { Button, Form } from "reactstrap";
import Select from 'react-select';
import config from '../../config/config';

import '../../assets/css/style.css';
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader";
import DemoFooter from "components/Footers/DemoFooter.js";

import backArrow from '../../assets/img/campaign/back-arrow.svg';

const selectStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#FFF" : styles.color,
    backgroundColor: state.isSelected ? "#fff" : styles.color,
    cursor: state.isDisabled ? 'pointer' : 'default',
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    "&:hover": {
      color: "#000",
      backgroundColor: "#FFF"
    }
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? "#fff" : 0,
    borderColor: state.isFocused ? "#000" : "#f0f0f0",
    "&:hover": {
      borderColor: state.isFocused ? "#000" : "#f0f0f0"
    }
  })
};
class FaqPage extends React.Component {
  constructor() {
    super()
    this.go2HomePage = this.go2HomePage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.state = {
      loginfields: { name: '', email: '', mobile: '', explination: '' },
      errors: {},
      selectedFilterId: 'Select Issue',
      selectedFilterName: 'Select Issue',
      documents: [],
      faqData: [],
    }
  }
  componentDidMount() {
    config.faqPageHeader = 'yes';
    const faqIssues = [
      {
        "filterId": "I’m having trouble signing up as a user",
        "filterName": "I’m having trouble signing up as a user"
      },
      // {
      //   "filterId": " I’m having trouble signing up as an NGO",
      //   "filterName": " I’m having trouble signing up as an NGO"
      // },
      {
        "filterId": "I would like to request a refund",
        "filterName": "I would like to request a refund"
      },
      {
        "filterId": "I would like to recommend an NGO/organisation",
        "filterName": "I would like to recommend an NGO/organisation"
      },
      {
        "filterId": "I would like to provide some feedback on MaybankHeart",
        "filterName": "I would like to provide some feedback on MaybankHeart"
      },
      {
        "filterId": "Others",
        "filterName": "Others"
      }
    ];

    this.setState({ faqIssuesArr: faqIssues });

    if (this.props.location && this.props.location.recommend === true) {
      this.setState({ selectedFilterId: faqIssues[2].filterId, selectedFilterName: faqIssues[2].filterName });
    } else {
      this.setState({ selectedFilterId: faqIssues });
    }
    this.getFaq();
  }
  getFaq() {
    axios.get(config.APP_URL + "api/FAQ?sort=createAt", {
      headers: {
        "Content-Type": "application/json",
      },
    }).then(res => {
      ReactGA.event({
        category: "User",
        action: "Viewed FAQs",
      });
      const faqData = res.data.data;
      this.setState({ faqData });
      //console.log(JSON.stringify(faqData));
    }).catch(e => console.log(e))

  }
  getFiles(files) {
    this.setState({ documents: files })
  }
  go2HomePage() {
    this.props.history.push('/');
  };
  handleFilterChange(event) {
    this.setState({ selectedFilterId: event.filterId });
    this.setState({ selectedFilterName: event.filterName });
  }
  handleChange(e) {
    let loginfields = this.state.loginfields;
    loginfields[e.target.name] = e.target.value;
    this.setState({
      loginfields
    });
  }
  handleValidation() {
    let loginfields = this.state.loginfields;
    let errors = {};
    let formIsValid = true;
    if (!loginfields["name"]) {
      formIsValid = false;
      errors["name"] = "*Your name is required";
    } else if (!loginfields["name"].match(/^[A-Za-z'\s.,\-@]{5,60}$/)) {
      formIsValid = false;
      errors["name"] = "*Please enter valid name";
    }
    if (!loginfields["email"]) {
      formIsValid = false;
      errors["email"] = "*Your email address is required";
    } else if (!loginfields["email"].match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      formIsValid = false;
      errors["email"] = "*Your email address is invalid.";
    }
    if (!loginfields["mobile"]) {
      formIsValid = false;
      errors["mobile"] = "*Your mobile number is required";
    } else if (!loginfields["mobile"].match(/^[0-9]{9,12}$/)) {
      formIsValid = false;
      errors["mobile"] = "*Your mobile number is invalid.";
    }
    if (!loginfields["explination"]) {
      formIsValid = false;
      errors["explination"] = "*Explanation is required";
    } else if (!loginfields["explination"].match(/^[A-Za-z'\s.,\-@]{2,1000}$/)) {
      formIsValid = false;
      errors["explination"] = "*Please enter Explanation";
    }


    this.setState({ errors: errors });
    return formIsValid;
  }
  formSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      const headers = {
        'Content-Type': 'application/json',
      }
      const data = {
        name: this.state.loginfields.name,
        email: this.state.loginfields.email,
        phone: this.state.loginfields.mobile,
        type: this.state.selectedFilterName,
        filename: this.state.documents.name,
        fileContent: this.state.documents.base64,
        message: this.state.loginfields.explination,
      }
      ReactGA.event({
        category: "User",
        action: "Sent Feedback",
        label: this.state.selectedFilterName
      });
      axios.post(config.APP_URL + "getFeedback", data, {
        headers: headers
      }).then(res => {
        if (res.status === 202) {
          toastr.success(res.data);
          this.props.history.push('/');
        }
      }).catch(e => {
        toastr.error('Error while submitting the request');
      })
    } else {
      console.log("Form has errors.");
    }

  }
  render() {
    return <div>
      <ExamplesNavbar />
      <LandingPageHeader />

      <div className="faqBanner">
        <div className="container" style={{ paddingTop: '100px' }}>
          <div>
          <button className="back-btn"  onClick={this.go2HomePage}> <img src={backArrow}  style={{ width: '13px'}} alt="back button" /> BACK </button>
            </div>
          <div className="ngo-and-community-txt">FAQ</div>
        </div>
      </div>
      <div style={{ background: 'rgb(240, 240, 240)', paddingBottom: '25px', paddingTop: '45px' }}>
        <div className="container">
          <div className="row margin-bottom-10">
            <div className="col-md-1"></div>
            <div className="col-md-7">
              {this.state.faqData.map((faq) => {
                return <div key={faq._id}>
                  <div className="faq-title">{faq.question}</div>
                  <div>{faq.answer}</div>
                </div>
              })}
            </div>
            {/* <div className="col-md-3" style={{ marginTop: '40px' }}>
              <div className="writetous">
                <div className="wan2helpngo">Want to help an NGO?</div>
                <div className="referneedyNGO">Refer a needy NGO to sign up with us. You can tell us the details, we will help them onboard here</div>
                <div>  <img src={writetous} style={{ width: '100%' }} alt="ngo image" /></div>
              </div>
              <div className="writetoneedy">
                <div className="referneedyNGO">
                  <div style={{ paddingTop: '10px' }}>For any queries, Please email us at </div>
                  <div style={{ color: '#fdc83e' }}> maybankheart@maybank.com</div>
                </div>
              </div>
            </div> */}
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
      <div>
        <div className="container" style={{ paddingTop: '35px', paddingBottom: '50px' }}>
          <div className="row margin-bottom-10">
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="faq-title">Need help ?</div>
              <div>
                Help an NGO or report an issue. Please provide a vaild email ID and contact details. We will be contacting you through the details you provide.
  </div>
            </div>
          </div>
          <div className="row margin-bottom-10">
            <div className="col-md-1"></div>
            <div className="col-md-6 mr-auto">
              <Form className="register-form" onSubmit={this.formSubmit.bind(this)} style={{ paddingTop: '25px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <input type="text" placeholder="Name" className="login-field" name="name"
                    onChange={this.handleChange} value={this.state.loginfields.name}
                  />
                  <div className="errMsg">{this.state.errors.name}</div>
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <input type="text" placeholder="Email" className="login-field" name="email"
                    onChange={this.handleChange} value={this.state.loginfields.email}
                  />
                  <div className="errMsg">{this.state.errors.email}</div>
                </div>
                <div>
                  <input type="text" placeholder="Mobile" className="login-field" name="mobile"
                    onChange={this.handleChange} value={this.state.loginfields.mobile}
                  />
                  <div className="errMsg">{this.state.errors.mobile}</div>
                </div>
                <div style={{ marginTop: '15px' }}>
                  <Select
                    styles={selectStyles}
                    getOptionValue={option => option.filterId}
                    getOptionLabel={option => option.filterName}
                    onChange={this.handleFilterChange}
                    options={this.state.faqIssuesArr}
                    value={this.state.selectedFilterId}
                    placeholder={this.state.selectedFilterName}
                  />
                </div>
                <div style={{ marginTop: '15px' }}>
                  <span>Attach suppporting files if any</span> <span className="choosefile"><FileBase64 onDone={this.getFiles.bind(this)} /></span>
                </div>
                <div style={{ marginTop: '15px' }}>
                  <textarea value={this.state.loginfields.explination} style={{ height: '65px' }} placeholder="Explain the support you are looking for" className="login-field" name="explination" onChange={this.handleChange} />
                  <div className="errMsg">{this.state.errors.explination}</div>
                </div>

                <Button block className="support-button-payment" type="submit" style={{ marginTop: '50px', width: '150px' }}>
                  SEND
                  </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <DemoFooter />

    </div>;
  }
}
export default withRouter(FaqPage);
