import React, {useState, useEffect} from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import $ from "jquery";
import {Button, Modal, Table, Form} from "reactstrap";
import {useHistory, useLocation} from "react-router-dom";
import Tabs from "../../components/Tabs/Tabs";
import "../../assets/css/style.css";
import config from "../../config/config";
import backArrow from "../../assets/img/campaign/back-arrow.svg";
import processingFee from "../../assets/img/campaign/process-icon.svg";
import close from "../../assets/img/close.svg";
import paymentFailed from "../../assets/img/campaign/payment-failed.svg";
import supportIcon from "../../assets/img/campaign/support-icon.svg";
import visa from "../../assets/img/campaign/visa.svg";
import master from "../../assets/img/campaign/master.svg";
import amex from "../../assets/img/campaign/amex.svg";
import popupBlocker from "../../assets/img/popup-unblocker.png";
import defaultBanner from "../../assets/img/banners/defaultBanner.jpg";

import Pdf from "../../assets/pdf/DisablePopupBlocker.pdf";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader";
import DemoFooter from "components/Footers/DemoFooter.js";

import campaignObjectAction from "../../actions/campaignAction";

import {useSelector, useDispatch} from "react-redux";

function SupportPage() {
  const history = useHistory();

  // States
  const [failedModal, setFailedModal] = useState(false);
  const [isAnonymousDonation, setIsAnonymousDonation] = useState(false);
  const [state, setState] = useState({
    supportFields: {
      name: undefined,
      ic: undefined,
      mobileNo: undefined,
      address: undefined,
    },
    errors: {},
  });

  const [taxExempt, setTaxExempt] = useState(false);
  const [payload, setPayload] = useState("");
  const [windowURL, setWindowURL] = useState("");
  const [otherPayment, setOtherPayment] = useState("");
  const [minutes, setMinutes] = useState(8);
  const [seconds, setSeconds] = useState(0);
  const [fund, setFund] = useState("RM 30");
  const [payType, setPayType] = useState("MGATE-MV");
  const [campBanner, setCampBanner] = useState(defaultBanner);

  const [thankUModal, setThankUModal] = useState(false);
  const [noAdminModal, setNoAdminModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [processModal, setProcessModal] = useState(false);
  const [campTitle, setCampTitle] = useState("");
  const [modalDisplayName, setModalDisplayName] = useState("User");
  const [campId, setCampId] = useState("");

  const [rm30btn, setRm30btn] = useState(true);
  const [rm50btn, setRm50btn] = useState(false);
  const [rm100btn, setRm100btn] = useState(false);

  const [m2uBtn, setM2uBtn] = useState(false);
  const [visaBtn, setVisaBtn] = useState(true);
  const [amexBtn, setAmexBtn] = useState(false);

  const user_data = useSelector(state => state["user"].user_data);
  const campaign_data = useSelector(state => state["campaign"].campaign_data);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const supportFields = state.supportFields;
    supportFields[e.target.name] = e.target.value;
    setState({...state, supportFields});
  };

  const checkPayMeth = (resData) => {
    if (resData && resData.payType) {
      switch (resData.payType) {
        case "MGATE-MV":
        case "MGATE-AMEX":
          const keyStr = resData["encString"];
          $("#MERCHANT_ACC_NO").val(resData["merchantAccountNo"]);
          $("#MERCHANT_TRAN_ID").val(resData.refNum);

          let amount = "0";
          if (otherPayment === "") {
            if (fund === "RM 30") {
              amount = "30";
            } else if (fund === "RM 50") {
              amount = "50";
            } else {
              amount = "100";
            }
          } else {
            amount = otherPayment;
          }
          if (amount === "6655") {
            amount = "1";
          }
          const amtData = parseFloat(amount).toFixed(2);
          $("#AMOUNT").val(amtData);
          $("#TXN_SIGNATURE").val(keyStr);
          $("#TXN_DESC").val("MaybankHeartDonation");
          $("#RETURN_URL").val(window.location.origin + config.ebpgCallbackURL);
          document["EBPG"].action = resData["ebpgCardURL"];
          document["EBPG"].submit();

          break;
        default:
          $("#q").val(resData.encryptedString);
          document["M2UPayment"].action = resData.actionUrl;
          document["M2UPayment"].submit();
          break;
      }
    } else {
      let donAmount;
      if (otherPayment === "") {
        if (fund === "RM 30") {
          donAmount = "30";
        } else if (fund === "RM 50") {
          donAmount = "50";
        } else {
          donAmount = "100";
        }
      } else {
        donAmount = otherPayment;
      }
      let data;
      switch (payType) {
        case "MGATE-MV":
        case "MGATE-AMEX":
          data = {
            donAmount: donAmount,
            campaignID: campId,
            payType,
          };
          if (isAnonymousDonation) {
            data["isAnonymous"] = true;
          }
          donate(data);
          break;
        default:
          data = {
            donAmount: donAmount,
            campaignID: campId,
            payType,
          };
          if (isAnonymousDonation) {
            data["isAnonymous"] = true;
          }
          donate(data);
          break;
      }
    }
    if (isAnonymousDonation) {
      setIsAnonymousDonation(false);
    }
  };

  const donate = async (data) => {

    let reqObj;
    let headers;

    if (data.isAnonymous === true) {
      headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
      };
      reqObj = {
        campaignID: data.campaignID,
        donAmount: data.donAmount,
        isAnonymous: data.isAnonymous,
        payType: data.payType,
      };
    } else {
      let accessToken;
      if (user_data) {
        accessToken = user_data.token;
      }
      //console.log('userdata '+JSON.stringify(user_data));
      headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken,
      };
      reqObj = {
        campaignID: data.campaignID,
        donAmount: data.donAmount,
        payType: data.payType,
      };
    }

    reqObj = {
      ...reqObj,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    }

    if (taxExempt) {
      reqObj = {
        ...reqObj,
        name: state.supportFields.name,
        ic: state.supportFields.ic,
        mobileNo: state.supportFields.mobileNo,
        address: state.supportFields.address,
      };
    }

    const {
      status,
      data: {referenceNumber, actionUrl, encryptedString} = {},
    } = await axios.post(`${config.APP_URL}payment/donate`, reqObj, {
      headers: headers,
    });
    if (status !== 200) {
      console.log("Error");
    }

    config.loginCheckId = "login-first";

    // GA Event
    ReactGA.event({
      category: "User",
      action: "Made a donation",
      label: `Campaign ID: ${data.campaignID} Payment Type: ${payType}`,
    });

    // Update form info
    setWindowURL(actionUrl);
    setPayload(encryptedString);

    // Open new windows
    switch (payType) {
      case "MAYBANK2U":
        document["M2U"].submit();
        break;

      case "MGATE-MV":
      case "MGATE-AMEX":
        document["MGATE"].submit();
        break;

      default:
        break;
    }

    // Checking donation status on period
    const checkPaymentStatus = setInterval(async () => {
      const {data} = await axios.get(
          `/request/donationStatus/${referenceNumber}`, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      if (data.status !== "PENDING") {
        clearInterval(checkPaymentStatus);
        setProcessModal(false);
        history.push({
          pathname: "/payment-result",
          m2uPaymentResult: data,
        });
      }
    }, 5000);
  };
  const toggleProcessModal = () => {
    setProcessModal(true);
    setThankUModal(false);
    checkPayMeth();
  };
  const toggleNoAdminModal = () => {
    setNoAdminModal(!noAdminModal);
  };
  const toggleThankUModal = () => {
    setIsAnonymousDonation(true);
    setConfirmModal(false);
    setThankUModal(true);

    setModalDisplayName("Anonymous User");
  };
  const closeThankUModal = () => {
    setThankUModal(false);
  };
  const closeNoAdminModal = () => {
    setNoAdminModal(false);
  };
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };
  const go2Login = () => {
    config.loginCheckId = "login-later";
    history.push("/login-page");
    config.campaignPageHeader = "no";
  };
  const rm30Click = () => {
    setRm30btn(true);
    setRm50btn(false);
    setRm100btn(false);
    setFund("RM 30");
  };
  const rm50Click = () => {
    setRm30btn(false);
    setRm50btn(true);
    setRm100btn(false);
    setFund("RM 50");
  };
  const rm100Click = () => {
    setRm30btn(false);
    setRm50btn(false);
    setRm100btn(true);
    setFund("RM 100");
  };
  const otherAmountChange = event => {
    setOtherPayment(event.target.value);
    if (event.target.value) {
      setRm30btn(false);
      setRm50btn(false);
      setRm100btn(false);
    } else {
      setRm30btn(true);
      setRm50btn(false);
      setRm100btn(false);
    }
  };
  const m2uClick = () => {
    setM2uBtn(true);
    setVisaBtn(false);
    setAmexBtn(false);
    setPayType("MAYBANK2U");
  };
  const visaClick = () => {
    setVisaBtn(true);
    setM2uBtn(false);
    setAmexBtn(false);
    setPayType("MGATE-MV");
  };
  const amexClick = () => {
    setAmexBtn(true);
    setM2uBtn(false);
    setVisaBtn(false);
    setPayType("MGATE-AMEX");
  };

  const formSubmit = (e) => {
    e.preventDefault();
    taxExempt === true && !handleValidation() ?
        console.log("Form has errors.") :
        checkLoggedIn();
  };

  const handleValidation = () => {
    let supportFields = state.supportFields;
    let errors = {};
    let formIsValid = true;

    if (supportFields["name"] &&
        !supportFields["name"].match(/^[A-Za-z'\s.,\-@]{5,60}$/)) {
      formIsValid = false;
      errors["name"] = "*Please enter valid name";
    }
    if (supportFields["ic"] &&
        !supportFields["ic"].match(/^\d{6}[-\s]?\d{2}[-\s.]?\d{4}$/)) {
      formIsValid = false;
      errors["ic"] = "*Please enter valid IC in the following format: XXXXXX-XX-XXXX";
    }
    if (supportFields["mobileNo"] &&
        !supportFields["mobileNo"].match(/^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/)) {
      formIsValid = false;
      errors["mobileNo"] = "*Please enter a valid Malaysia mobile number.";
    }
    if (supportFields["address"] && !supportFields["address"]) {
      formIsValid = false;
      errors["address"] = "*Address is required.";
    }

    setState({...state, errors});

    return formIsValid;
  };

  const checkLoggedIn = () => {
    let accessToken;
    if (user_data) {
      accessToken = user_data.token;
    }
    //console.log('userdata '+JSON.stringify(user_data));
    axios.get(`${config.APP_URL}is-login`, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken,
      },
    }).then(res => {
      if (res.data.status === "success") {
        if (res.data.role === "A") {
          setNoAdminModal(true);
        } else {
          setThankUModal(true);
          setModalDisplayName(res.data.displayName);
        }
      } else {
        setConfirmModal(true);
      }
    }).catch(e => console.log(e));
  };

  const go2HomePage = () => {
    history.push("/");
  };
  const {
    campaignData,
    displayNameFrmLogin,
    campaignId,
    campaignTitle,
  } = useLocation();

  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    // Timeout
    if (!minutes && !seconds) {
      setProcessModal(false);
      setFailedModal(true);
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  useEffect(() => {
    if (displayNameFrmLogin !== undefined && displayNameFrmLogin !== null) {
      setModalDisplayName(displayNameFrmLogin);
    }
    if (campaign_data !== undefined && campaign_data !== null) {
      setCampTitle(campaign_data.title);
      setCampId(campaign_data.id);
    }
    if (campaignData !== undefined && campaignData !== null) {
      setCampTitle(campaignData.title);
      setCampId(campaignData.id);
      if (campaignData.campaignBanner.length > 0) {
        setCampBanner(campaignData.campaignBanner);
      } else {
        setCampBanner(defaultBanner);
      }
      dispatch(campaignObjectAction(campaignData));
    } else {
      console.log("No campaign data available");
      history.push("/");
    }
    if (campaignId !== undefined && campaignId !== null) {
      setCampId(campaignId);
    }
    if (campaignTitle !== undefined && campaignTitle !== null) {
      setCampTitle(campaignTitle);
    }
  }, [
    displayNameFrmLogin,
    campaign_data,
    campaignData,
    campaignId,
    campaignTitle,
    dispatch,
    history]);
  useEffect(() => {
    //window.scrollTo(0, 0);
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  const closeFailedModal = () => {
    setFailedModal(false);
    history.push("/");
  };

  useEffect(() => {
    setTaxExempt(campaign_data.labels.includes("Tax Exempt"));
  }, [campaign_data.labels]);

  return (
      <>
        <ExamplesNavbar/>
        <LandingPageHeader/>
        <div style={
          {
            backgroundImage: `linear-gradient(to bottom, rgba(31, 26, 26, 0.5), #000000A3), url(${campBanner.default ?
                campBanner.default :
                campBanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "545px",
            paddingTop: "35px",
            backgroundPosition: "center",
          }
        }>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <button className="back-btn" onClick={go2HomePage}><img
                      src={backArrow} style={{width: "13px"}}
                      alt="back button"/> BACK
                  </button>
                </div>
                <div className="ngo-and-community-txt">{campTitle}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="support-div-block">
          <div className="container">
            <div>
              <div className="row">
                <div className="col-md-12 support-div-top">
                <span
                    className="supportCamHead">SUPPORT THIS CAMPAIGN</span><span
                    style={{float: "right"}}> <img src={close}
                                                   onClick={go2HomePage}
                                                   style={{
                                                     width: "18px",
                                                     cursor: "pointer",
                                                   }} alt="ngo"/></span>
                </div>
              </div>
            </div>
            <div className="row tabOver">
              <Tabs>
                <div style={{
                  padding: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  fontSize: "18px",
                }}>Support Fund
                </div>
                <span>  {
                  <div>
                    {
                        taxExempt &&
                        <Form className="d-flex flex-column support-form"
                              onSubmit={formSubmit.bind(this)}>
                          <div style={{marginBottom: "15px"}}>
                            <input type="text" placeholder="Name"
                                   className="login-field" name="name"
                                   onChange={handleChange}
                                   value={state.supportFields.name}
                            />
                            <div
                                className="ml-auto errMsg">{state.errors.name}</div>
                          </div>
                          <div style={{marginBottom: "15px"}}>
                            <input type="text" placeholder="IC"
                                   className="login-field" name="ic"
                                   onChange={handleChange}
                                   value={state.supportFields.ic}
                            />
                            <div
                                className="ml-auto errMsg">{state.errors.ic}</div>
                          </div>
                          <div style={{marginBottom: "15px"}}>
                            <input type="text" className="login-field"
                                   name="mobileNo"
                                   onKeyDown={(e) => !/[0-9]/.test(e.key) &&
                                       e.preventDefault()}
                                   placeholder="Mobile Number"
                                   onChange={handleChange}
                                   value={state.supportFields.mobileNo}
                            />
                            <div
                                className="ml-auto errMsg">{state.errors.mobileNo}</div>
                          </div>
                          <div style={{marginBottom: "15px"}}>
                            <input type="text" placeholder="Address"
                                   className="login-field" name="address"
                                   onChange={handleChange}
                                   value={state.supportFields.address}
                            />
                            <div
                                className="ml-auto errMsg">{state.errors.address}</div>
                          </div>
                        </Form>
                    }
                    <div className="row">
                      <div className="col-md-12">
                      <span>
                        <button style={{
                          padding: "9px 22px",
                          color: "#262626",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }} className={rm30btn ?
                            "fundButtonTrue" :
                            "fundButtonFalse"}
                                onClick={rm30Click}>
                          RM 30
                    </button>
                        <button style={{
                          padding: "9px 22px",
                          color: "#262626",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }} className={rm50btn ?
                            "fundButtonTrue" :
                            "fundButtonFalse"}
                                onClick={rm50Click}>
                          RM 50
                    </button>
                        <button style={{
                          padding: "9px 22px",
                          color: "#262626",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }} className={rm100btn ?
                            "fundButtonTrue" :
                            "fundButtonFalse"}
                                onClick={rm100Click}>
                          RM 100
                    </button></span>
                        <span style={{display: "inline-block"}}> <input
                            type="number" placeholder="Other Amount"
                            onChange={otherAmountChange}
                            className="login-field other-amount"
                            style={{fontSize: "15px", height: "50px"}}/></span>
                      </div>
                    </div>

                    <div className="select-payment-method">Select a Payment
                      Mode
                    </div>
                    <div>
                      <button style={{
                        padding: "9px 22px",
                        color: "#262626",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }} className={m2uBtn ? "buttonTrue" : "buttonFalse"}
                              onClick={m2uClick}>
                        maybank2u
                      </button>
                      <button className={visaBtn ? "buttonTrue" : "buttonFalse"}
                              onClick={visaClick}>
                        <img src={visa} style={{width: "30%"}} alt="visa card"/>
                        <img src={master} style={{width: "30%"}}
                             alt="master card"/>
                      </button>
                      <button className={amexBtn ? "buttonTrue" : "buttonFalse"}
                              onClick={amexClick}>
                        <img src={amex} style={{width: "20%"}} alt="amex card"/>
                      </button>
                    </div>
                    <div style={{marginTop: "40px", marginLeft: "-5px"}}>
                      <Button className="btn-round ml-1 support-button-payment"
                              onClick={formSubmit.bind(this)} color="default"
                              type="button" style={{
                        width: "230px",
                        height: "60px",
                        fontSize: "17px",
                        paddingTop: "10px",
                      }}>
                        Support <img className="shareIcon" src={supportIcon}
                                     alt="Support Campaign"/>
                      </Button>
                    </div>
                    <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
                      <div className="modal-header">
                        <button
                            aria-label="Close"
                            className="close"
                            type="button"
                            onClick={closeConfirmModal}>
                        <span aria-hidden={true}><img src={close} style={{
                          width: "18px",
                          cursor: "pointer",
                        }} alt="ngo"/></span>
                        </button>
                        <h5 className="modal-title text-center"
                            id="exampleModalLabel">
                          Confirm Action </h5>
                      </div>
                      <div className="modal-body">

                        <div className="modal-text-size1">Are you sure you want
                          to Donate Anonymously ?
                        </div>
                        <div className="modal-text-size2">Note: No receipt will
                          be generated for this donation and the donation will
                          not appear on your dashboard.
                        </div>
                        <div className="row" style={{marginTop: "25px"}}>
                          <div className="col-md-6"
                               style={{textAlign: "center"}}>
                            <Button onClick={toggleThankUModal}
                                    className="btnYes" color="default"
                                    type="button">
                              Yes
                            </Button>
                          </div>
                          <div className="col-md-6"
                               style={{textAlign: "center"}}>
                            <Button onClick={go2Login} className="btnYes"
                                    color="default" type="button">
                              No
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <Modal isOpen={thankUModal} toggle={toggleThankUModal}>
                      <div className="modal-header">
                        <button
                            aria-label="Close"
                            className="close"
                            type="button"
                            onClick={closeThankUModal}>
                        <span aria-hidden={true}><img src={close} style={{
                          width: "18px",
                          cursor: "pointer",
                        }} alt="ngo"/></span>
                        </button>
                        <h5 className="modal-title text-center"
                            id="exampleModalLabel">
                          Thank You for your Interest </h5>
                      </div>
                      <div className="modal-body">
                        <div className="modal-text-size1">You will be redirected
                          to the Payment gateway.
                          For security reasons, a new tab or pop-up will appear.
                        </div>
                        <div className="plzEnsure">Please ensure pop-up blocker
                          is disabled
                        </div>
                        <div className="row">
                          <div className="col-md-2"></div>
                          <div className="col-md-8 popup-blocker">
                            <a href={Pdf} rel="noopener noreferrer"
                               target="_blank">
                              <img src={popupBlocker} style={{
                                height: "40px",
                                width: "40px",
                                marginRight: "15px",
                              }} alt="ngo"/><span style={{
                              fontSize: "13px",
                              fontWeight: "normal",
                              marginRight: "15px",
                              color: "#000",
                            }}>How to disable pop-up blocker</span>
                            </a>
                          </div>
                          <div className="col-md-2"></div>
                        </div>
                        <div className="popContinue"><Button
                            className="btn-round support-button-payment"
                            onClick={toggleProcessModal} color="default"
                            type="button">
                          Continue
                        </Button></div>
                      </div>
                    </Modal>
                    <Modal isOpen={noAdminModal} toggle={toggleNoAdminModal}>
                      <div className="modal-header">
                        <button
                            aria-label="Close"
                            className="close"
                            type="button"
                            onClick={closeNoAdminModal}>
                        <span aria-hidden={true}><img src={close} style={{
                          width: "18px",
                          cursor: "pointer",
                        }} alt="ngo"/></span>
                        </button>
                        <h5 className="modal-title text-center"
                            id="exampleModalLabel">
                          Thank You for your Interest </h5>
                      </div>
                      <div className="modal-body">
                        <div className="modal-text-size1"
                             style={{fontSize: "17px"}}>You are logged in as an
                          Admin. Please login as a Donor to support.
                        </div>
                        <div className="popContinue"><Button
                            className="btn-round support-button-payment"
                            onClick={closeNoAdminModal} color="default"
                            type="button">
                          Close
                        </Button></div>
                      </div>
                    </Modal>
                    <Modal isOpen={processModal} toggle={toggleProcessModal}>
                      <div className="modal-header" style={{textAlign: "left"}}>
                        <h5 className="modal-title" id="exampleModalLabel">
                          Payment Processing... </h5>
                      </div>
                      <div className="modal-body">
                        <div className="plzWait">Please Wait... your request is
                          being processed.
                        </div>
                        <div className="pop-blog-text">Please do not close this
                          window or click the Back button on your browser.
                        </div>
                        <div className="pop-blog-text" style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}>If nothing happens, Please check pop-up blocker and
                          retry
                        </div>
                        <div className="row">
                          <div className="col-md-2"></div>
                          <div className="col-md-8 popup-blocker">
                            <a href={Pdf} rel="noopener noreferrer"
                               target="_blank">
                              <img src={popupBlocker} style={{
                                height: "40px",
                                width: "40px",
                                marginRight: "15px",
                              }} alt="ngo"/><span style={{
                              fontSize: "13px",
                              fontWeight: "normal",
                              marginRight: "15px",
                              color: "#000",
                            }}>How to disable pop-up blocker</span>
                            </a>
                          </div>
                          <div className="col-md-2"></div>
                        </div>
                        <div style={{
                          fontSize: "22px",
                          paddingTop: "10px",
                          fontWeight: "bold",
                        }}>
                          <center>
                            <div> {minutes === 0 && seconds === 0
                                ?
                                null
                                :
                                <span
                                    className="payment-timer"> {minutes}:{seconds <
                                10 ? `0${seconds}` : seconds}</span>
                            }</div>
                          </center>
                        </div>
                      </div>
                    </Modal>
                    <div className="processing-fee">
                      <img src={processingFee} style={{
                        width: "16px",
                        marginRight: "2px",
                        marginTop: "-2px",
                      }} alt="processing fee details"/> Processing Fee for
                      payment modes
                    </div>
                    <div>
                      <Table responsive striped hover>
                        <thead style={{background: "#000", color: "#fff"}}>
                        <tr>
                          <th scope="col">Channels</th>
                          <th scope="col">MaybankHeart</th>
                          <th scope="col">Maybank2u</th>
                          <th scope="col">Master / Visa</th>
                          <th scope="col">AMEX</th>
                          <th scope="col">Inter-Bank</th>
                          <th scope="col">Donation after Fee (MYR)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>Maybank2u Transfer</td>
                          <td>No Fee</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>100.00</td>
                        </tr>
                        <tr>
                          <td>Maybank Credit Card(Master / VISA)</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>-</td>
                          <td>100.00</td>
                        </tr>
                        <tr>
                          <td>Maybank Debit Card(Master / VISA)</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>-</td>
                          <td>100.00</td>
                        </tr>
                        <tr>
                          <td>Maybank (AMEX)</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>-</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>100.00</td>

                        </tr>
                        <tr>
                          <td>Non Maybank Credit Card</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>1.10%</td>
                          <td>-</td>
                          <td>-</td>
                          <td>98.90</td>
                        </tr>
                        <tr>
                          <td>Non Maybank Credit Card</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td>1.10%</td>
                          <td>-</td>
                          <td>-</td>
                          <td>98.90</td>
                        </tr>
                        <tr>
                          <td>Interbank Transfer (FPX)</td>
                          <td>No Fee</td>
                          <td>-</td>
                          <td></td>
                          <td>Coming Soon!</td>
                          <td></td>
                          <td></td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                }
              </span>
              </Tabs>
            </div>
          </div>
        </div>
        <Form name="M2U" target="M2U" method="POST" action={windowURL}>
          <input type="hidden" name="q" value={payload} id="q"/>
          <input type="hidden" name="i" value="OT" id="i"/>
        </Form>
        <Form name="MGATE" method="POST" action={windowURL}>
          <input type="hidden" name="payload" value={payload}/>
        </Form>
        <DemoFooter/>
        <div>
        </div>
        <div>
          <Modal isOpen={failedModal}>
            <div className="modal-body" style={{paddingBottom: "50px"}}>
              <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={closeFailedModal}>
              <span aria-hidden={true}><img src={close} style={{
                width: "18px",
                cursor: "pointer",
              }} alt="ngo"/></span>
              </button>
              <div className="row">
                <div className="col-md-12">
                  <div style={{textAlign: "center"}}>
                    <img src={paymentFailed} className="payment-success-icon"
                         alt="ngo"/>
                    <div className="payment-modal-head1">TIMED OUT!</div>
                    <div style={{
                      paddingBottom: "7px",
                      fontSize: "16px",
                      paddingTop: "20px",
                    }}>
                      Dear {modalDisplayName},
                    </div>
                    <div style={{fontSize: "16px"}}>Your contribution to
                      campaign
                    </div>
                    <div style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#222222",
                    }}>{campTitle}</div>
                    <div style={{fontSize: "16px"}}>was unsuccessful.</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
  );
}

export default SupportPage;



