import React from "react";
import eduIcon from "../../assets/img/campaign/cat-edu.svg";
import allIcon from "../../assets/img/campaign/cat-all.svg";
import commIcon from "../../assets/img/campaign/cat-commu.svg";
import disaIcon from "../../assets/img/campaign/cat-disa.svg";
import artIcon from "../../assets/img/campaign/cat-art.svg";
import healthyIcon from "../../assets/img/campaign/cat-healthy.svg";
import enviIcon from "../../assets/img/campaign/cat-envi.svg";
import Tabs from "../../components/Tabs/Tabs";

// reactstrap components
import {Container} from "reactstrap";
import AllCategory from "views/index-sections/AllCategory";
import EducationCategory from "views/index-sections/EducationCategory";
import CommunityCategory from "views/index-sections/CommunityCategory";
import DisasterReliefCategory
  from "views/index-sections/DisasterReliefCategory";
import ArtsCategory from "views/index-sections/ArtsCategory";
import HealthyCategory from "views/index-sections/HealhtyCategory";
import EnvironmentCategory from "views/index-sections/EnvironmentCategory";

function HomeCategorySection() {
  return (
      <>
        <div>
          <Container style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "0px",
            textAlign: "center",
          }}>
            <Tabs>
              <div style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={allIcon} alt="Education Campaign"
                          className="cat-img"/></div>
                <div className="home-cate-title">All</div>
                <div className="home-cate-title">Campaigns</div>
              </div>
              <span>  {
                <div><AllCategory/></div>
              }
            </span>
              <div style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={eduIcon} alt="Education Campaign"
                          className="cat-img"/></div>
                <div className="home-cate-title">Education</div>
                <div className="home-cate-title" style={{color: "#F0F0F0"}}>.
                </div>
              </div>
              <span>  {
                <div><EducationCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={commIcon} alt="Community Empowderment"
                          className="cat-img"/></div>
                <div className="home-cate-title">Community</div>
                <div className="home-cate-title">Empowerment</div>
              </div>
              <span>
              {
                <div><CommunityCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={disaIcon} alt="Disaster Relief"
                          className="cat-img"/></div>
                <div className="home-cate-title"> Disaster</div>
                <div className="home-cate-title">Relief</div>
              </div>
              <span>
              {
                <div><DisasterReliefCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={artIcon} alt="Art Culture" className="cat-img"/>
                </div>
                <div className="home-cate-title"> Arts &</div>
                <div className="home-cate-title">Culture</div>
              </div>
              <span>
              {
                <div><ArtsCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={healthyIcon} alt="Healthy Living"
                          className="cat-img"/></div>
                <div className="home-cate-title"> Healthy</div>
                <div className="home-cate-title">Living</div>
              </div>
              <span>
              {
                <div><HealthyCategory/></div>
              }
            </span>
              <div
                  style={{padding: "10px", cursor: "pointer"}}>
                <div><img src={enviIcon} alt="Environmental Diversity"
                          className="cat-img"/></div>
                <div className="home-cate-title"> Environmental</div>
                <div className="home-cate-title">Diversity</div>
              </div>
              <span>
              {
                <div><EnvironmentCategory/></div>
              }
            </span>
            </Tabs>
          </Container>
        </div>
        {" "}
      </>
  );
}

export default HomeCategorySection;
