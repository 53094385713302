
import React from "react";
import { useHistory } from "react-router-dom";


// reactstrap components
import rightScroll from '../assets/img/campaign/right-scroll.svg';
import topScroll from '../assets/img/campaign/top-arrow.svg';

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// index sections
// import SectionButtons from "views/index-sections/SectionButtons.js";
// import SectionNavbars from "views/index-sections/SectionNavbars.js";
// import SectionNavigation from "views/index-sections/SectionNavigation.js";
// import SectionProgress from "views/index-sections/SectionProgress.js";
// import SectionNotifications from "views/index-sections/SectionNotifications.js";
// import SectionTypography from "views/index-sections/SectionTypography.js";
// import SectionJavaScript from "views/index-sections/SectionJavaScript.js";
// import SectionCarousel from "views/index-sections/SectionCarousel.js";
// import SectionNucleoIcons from "views/index-sections/SectionNucleoIcons.js";
// import SectionDark from "views/index-sections/SectionDark.js";
// import SectionLogin from "views/index-sections/SectionLogin.js";
// import SectionExamples from "views/index-sections/SectionExamples.js";
// import SectionDownload from "views/index-sections/SectionDownload.js";
import HomeTrendingSection from "views/index-sections/HomeTrendingSection";
import HomeCategorySection from "views/index-sections/HomeCategorySection";
import HomeNGOSection from "views/index-sections/HomeNGOSection";
import HomeCounterSection from "views/index-sections/HomeCounterSection";
// import HomeBlogSection from "views/index-sections/HomeBlogSection";

function Index() {

  const [isVisible, setIsVisible] = React.useState(false);
  const history = useHistory();
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  React.useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  const viewAllCampaigns = () => {
    history.push("/campaigns-page");
  };

  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      <div className="main deviceTop">
        <div className="container">
          <div className="row" style={{ padding: '20px' }}>
            <div className="col-md-4 col-sm-1 col-xs-12"></div>
            <div className="col-md-4 col-sm-4 col-xs-12" style={{ textAlign: 'center' }}><span className="section-heading">Trending Campaigns</span></div>
            <div className="col-md-4 col-sm-5 col-xs-12" style={{ paddingRight: '0px' }}><span onClick={viewAllCampaigns} style={{
              float: 'right', fontWeight: 'bold', paddingTop: '10px', color: '#000',
              cursor: 'pointer'
            }}>VIEW ALL CAMPAIGNS
        <img src={rightScroll} style={{ width: '40px' }} alt="left-scroll" /></span>
            </div>
          </div>
        </div>
        <HomeTrendingSection />
        <HomeCategorySection />

        <HomeCounterSection />
        <HomeNGOSection />


        {/* <HomeBlogSection/>
        <SectionButtons /> */}
        {/* <SectionNavbars /> */}
        {/* <SectionNavigation /> */}
        {/* <SectionProgress /> */}
        {/* <SectionNotifications />
        <SectionTypography />
        <SectionJavaScript />
        <SectionCarousel />
        <SectionNucleoIcons />
        <SectionDark />
        <SectionLogin />
        <SectionExamples />
        <SectionDownload /> */}


        <DemoFooter />
        <div>
          {isVisible &&
            <div onClick={scrollToTop} >
              <img src={topScroll} style={{ width: '40px' }} alt="left-scroll" className="scrolltotop" />
            </div>}
        </div>
      </div>
    </>
  );
}

export default Index;
