import './slider.sass';
import React, {useEffect, useState} from 'react';
import Slide from './Slide';
import NavigateButton from './NavigateButton';

export default function Slider({useNavigator = true, useArrow = true, animate = false, animateSpeed = 5000, images = [], startSlide = 0}) {
  const [activeSlide, setActiveSlide] = useState(startSlide);

  const prevSlide = () => {
    setActiveSlide((activeSlide === 0 ? images.length : activeSlide) - 1);
  };

  const nextSlide = () => {
    setActiveSlide(activeSlide === (images.length - 1) ? 0 : activeSlide + 1);
  };

  // Slideshow.
  useEffect(() => {
    const animation = setInterval(
      (animate && images.length > 1) ? nextSlide : () => {
      }, animateSpeed);

    return () => clearInterval(animation);
  });

  return (
    <div className='SliderContainer'>
      <div className="SliderWrapper">
        {images.length > 1 && useArrow && <div className="ArrowKeys">
          <button className="Arrow ArrowLeft" onClick={prevSlide}>{'<'}</button>
          <button className="Arrow ArrowRight"
                  onClick={nextSlide}>{'>'}</button>
        </div>}
        <div className="Slider">
          {images.map((image, index) => (
            <Slide key={`slide-${index}`} image={image}
                   active={index === activeSlide}/>
          ))}
        </div>
      </div>
      {images.length > 1 && useNavigator && <div className="Navigator">
        <div className="NavigatorWrapper">{
          images.map((_, index) => (
            <NavigateButton onClick={() => {
              setActiveSlide(index);
            }} key={`navigator-${index}`} active={index === activeSlide}/>
          ))
        }</div>
      </div>}
    </div>
  );
};
