
import React from "react";
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import rightScroll from '../../assets/img/campaign/right-scroll.svg';
import '../../assets/css/style.css';
// import close from '../../assets/img/close.svg';

class HomeNGOSection extends React.Component {
    constructor(props) {
        super(props)
        this.viewAllNgos = this.viewAllNgos.bind(this);
        this.state = {
            ngoArr: [],
            ngoArrSet6: [],
        }
    }
    componentDidMount() {
        axios.get("api/NGO", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': ''
            },
        }).then(res => {
            const ngoArr = res.data.data;
            const ngoArrSet6 = ngoArr.slice(0, 8);
            this.setState({ ngoArrSet6 });
        }).catch(e => console.log(e))
    }
    viewAllNgos() {
        this.props.history.push('/ngo-page');
    };
    render() {
        return <div style={{ background: '#fff', paddingBottom: '30px' }}>
            <div className="container">
                <div className="row" style={{ padding: '35px' }}>
                    <div className="col-md-12" style={{ textAlign: 'center' }}><span className="section-heading">NGOs With Us</span></div>
                </div>
                <div className="row">
                    {this.state.ngoArrSet6.map((ngo) => {
                        return <div className="col-md-3" key={ngo._id}>
                            <div className="ngocard padtopbottom15">
                                <img src={ngo.logo} className="ngoImgeSize" alt="ngo img" />
                            </div>
                        </div>
                    })}
                </div>
                <div className="row" style={{ padding: '20px' }}>
                    <div className="col-md-9 col-sm-7 col-xs-12"></div>
                    <div className="col-md-3 col-sm-5 col-xs-12" style={{ paddingRight: '0px' }}><span onClick={this.viewAllNgos} style={{
                        float: 'right', fontWeight: 'bold', paddingTop: '10px', color: '#000',
                        cursor: 'pointer'
                    }}>VIEW ALL NGOs
        <img src={rightScroll} style={{ width: '40px' }} alt="left-scroll" />
                    </span>
                    </div>
                </div>
            </div>
        </div>;
    }
}
export default withRouter(HomeNGOSection);
