
import React from "react";
import axios from 'axios';
import ReactGA from 'react-ga4';
// import sha256_digest from '../../components/Scripts/satellizerSHA256';
import { Button, Card, Form, Row, Col } from "reactstrap";
import toastr from 'toastr'
//import 'toastr/build/toastr.min.css';
import '../../assets/css/style.css';
import config from '../../config/config';

import close from '../../assets/img/close.svg';

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader";
import DemoFooter from "components/Footers/DemoFooter.js";


class ForgotPassword extends React.Component {
  constructor() {
    super()
    this.go2HomePage = this.go2HomePage.bind(this);
    this.goSignUpPage = this.goSignUpPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      loginfields: { email: '' },
      errors: {},
    }
  }
  go2HomePage() {
    this.props.history.push('/');
  };
  goSignUpPage() {
    this.props.history.push('/register-page');
  };
  handleChange(e) {
    let loginfields = this.state.loginfields;
    loginfields[e.target.name] = e.target.value;
    this.setState({
      loginfields
    });
}
  handleValidation() {
    let loginfields = this.state.loginfields;
    let errors = {};
    let formIsValid = true;

    if (!loginfields["email"]) {
        formIsValid = false;
        errors["email"] = "*Your email address is required";
    } else if (!loginfields["email"].match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
        formIsValid = false;
        errors["email"] = "*Your email address is invalid.";

    }

    this.setState({ errors: errors });
    return formIsValid;
}
  formSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      const headers = {
        'Content-Type': 'application/json',
    }
    const data = {
      email: this.state.loginfields.email
    }
    ReactGA.event({
      category: "User",
      action: "Requested forgot password",
      label: this.state.loginfields.email
    });
    axios.post(config.APP_URL + "verifyEmail", data, {
        headers: headers
    }).then(res => {
     console.log('resJson --- ' + JSON.stringify(res));
       if(res.status === 200){
         console.log('success case'+JSON.stringify(res.data));
         toastr.success(res.data.message);
         this.props.history.push('/');
        }else {
         toastr.error(res.data.message);
       }
      }).catch(e => {
        toastr.error(e.response.data.message);
      })

    } else {
      console.log("Form has errors.");
    }
  }
  render() {
    return <div style={{ background: '#F0F0F0' }}>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div className="container">
        <Row>
          <Col className="ml-auto mr-auto" lg="4">
            <Card className="forgot-register ml-auto mr-auto">
              <div><span className="title-login mx-auto">Forgot Password</span><span style={{ float: 'right' }}> <img src={close} onClick={this.go2HomePage} style={{ width: '18px', cursor: 'pointer' }} alt="ngo" /></span></div>
              <Form className="register-form" onSubmit={this.formSubmit.bind(this)}>
                <div style={{paddingTop: '30px'}}>
                    <lable className="forgotLable">Enter the email address used for registration</lable>
                  <input type="text" placeholder="Email" className="login-field" name="email"
                    onChange={this.handleChange} value={this.state.loginfields.email} style={{marginTop: '8px'}}
                  />
                  <div className="errMsg">{this.state.errors.email}</div>
                </div>
                <Button block className="support-button-payment" type="submit" style={{marginTop: '15px'}}>
                  SUBMIT
                  </Button>
              </Form>
           </Card>
          </Col>
        </Row>
      </div>
      <DemoFooter />

    </div>;
  }
}

export default ForgotPassword;
