const initialState = {
  user_data: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "user_object":
      return {
        user_data: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;