import React, {useState} from 'react';
import {Button} from 'reactstrap';
import {FacebookShareButton, WhatsappShareButton} from 'react-share';

// Images
import shareIcon from '../../assets/img/campaign/share-icon.svg';
import supportIcon from '../../assets/img/campaign/support-icon.svg';
import supportIconWhite from '../../assets/img/campaign/support-icon-white.svg';
import whatsappIcon from '../../assets/img/logo/whatsapp.png';
import facebookIcon from '../../assets/img/logo/facebook.svg';

// CSS
import '../../assets/css/trendButtons.css';

export default function({hasSupport=true, customClass, btnStyle='grey', style, campaign, handleSupport = () =>{}}) {
  const [open, setOpen] = useState(false);
  const url = `${window.location.origin}/campaign-details-page?Campaign=${campaign.id}`;
  const componentClass = 'trend-button-div' + (customClass ? ` ${customClass}` : '');
  const campaignStatus = campaign.status;

  const toggleDropDown = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const iconStyle = {
    'orange': supportIcon,
    'grey': supportIconWhite,
  };

  const supportButton = (
      <Button disabled={campaignStatus === 'Completed'} className="btn-round ml-1 support-button" color="default"
              type="button" onClick={handleSupport}>Support <img
          className="shareIcon" src={iconStyle[btnStyle]} 
          alt="Support Campaign"/>
      </Button>
  );

  const shareButtonClass = 'btn-round ml-1 share-button' + (hasSupport ? '' : ' single-button');

  return (
      <div className={[componentClass, btnStyle].join(' ') } style={style}>
        {hasSupport ? supportButton : ''}
        <Button className={shareButtonClass} color="primary"
                type="button" onClick={toggleDropDown}>Share <img
            className="shareIcon" src={shareIcon}
            alt={`Share`}/></Button>
        <div className={'dropdown' + (open ? ' active' : '')}>
          <div className="shareButtonWrapper">
            <img className={'shareFacebook'} alt={'Share to Facebook'} src={facebookIcon} />
            <FacebookShareButton
                className={'shareButton'}
                quote={`Donate to #MaybankHeart's ${campaign.title} today and continue the spirit of giving.`}
                hashtag={'#Maybank #HumanisingFinancialServices'}
                url={url}>Facebook</FacebookShareButton>
          </div>
          <div className="shareButtonWrapper">
            <img alt={'Share to WhatsApp'} src={whatsappIcon} />
            <WhatsappShareButton
                className={'shareButton'}
                title={`Donate to #MaybankHeart's ${campaign.title} today and continue the spirit of giving. #Maybank #HumanisingFinancialServices`}
                url={url}>Whatsapp</WhatsappShareButton>
          </div>
        </div>
      </div>
  );
}
