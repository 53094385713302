
import React from "react";
import axios from 'axios';
import { } from "reactstrap";
import Slider from '../slider';
import Loader from "react-loader-spinner";

class IndexHeader extends React.Component {
  constructor() {
    super()
    this.state = {
      homeBannersArr: [],
      loadingDiv: 'none',
      loading: false,
    }
  }
  componentDidMount() {
    this.setState({ loadingDiv: 'block' });
    this.setState({ loading: true });
    axios.get("api/Banner?active=true", {
      headers: {
        "Content-Type": "application/json",
        'Authorization': ''
      },
    }).then(res => {
      const homeBannersArr = res.data.data;
      this.setState({ homeBannersArr });
      this.setState({ loadingDiv: 'none' });
      this.setState({ loading: false });
    }).catch(e => console.log(e))
  }

  render() {
    return <div className="content-center" style={{ background: 'rgb(240, 240, 240)' }}>
            <div style={{ textAlign: 'center', display: this.state.loadingDiv }}>
              <Loader
                type="Rings"
                color="#FDC83E"
                height={100}
                width={100}
                visible={this.state.loading}
                style={{paddingTop: '200px'}}
              />
            </div>
      <Slider animate={true} useArrow={false} images={this.state.homeBannersArr}
      />
    </div>
  }
}

export default IndexHeader;


