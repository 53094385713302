
import React from "react";
import { useHistory } from "react-router-dom";
import config from '../../config/config';

// reactstrap components
import { Container } from "reactstrap";
import logo from '../../assets/img/footer/logo-footer.svg';
import facebook from '../../assets/img/footer/facebook.svg';
import twitter from '../../assets/img/footer/twitter.svg';
import instagram from '../../assets/img/footer/instagram.svg';
import youtube from '../../assets/img/footer/youtube.svg';

function DemoFooter() {
  const history = useHistory();

  const go2LoginPage = () => {
    history.push("/login-page");
    config.signupPageHeader = 'no';
    config.faqPageHeader = 'no';
    config.campaignPageHeader = 'no';
    config.ngoPageHeader = 'no';
  };
  const go2SignUpPage = () => {
    history.push("/register-page");
    config.loginPageHeader = 'no';
    config.faqPageHeader = 'no';
    config.campaignPageHeader = 'no';
    config.ngoPageHeader = 'no';
  };
  const go2FAQPage = () => {
    history.push("/faq-page");
    config.loginPageHeader = 'no';
    config.signupPageHeader = 'no';
    config.campaignPageHeader = 'no';
    config.ngoPageHeader = 'no';
  };
  const go2NGOPage = () => {
    history.push("/ngo-page");
    config.loginPageHeader = 'no';
    config.signupPageHeader = 'no';
    config.campaignPageHeader = 'no';
    config.faqPageHeader = 'no';
  };
  const go2CampaignsPage = (filter) => {
    history.push("/campaigns-page");
    config.footerFilterId = filter;
    config.loginPageHeader = 'no';
    config.signupPageHeader = 'no';
    config.ngoPageHeader = 'no';
    config.faqPageHeader = 'no';
  }

  return (
    <footer className="footer footer-black footer-white">
      <div style={{ borderBottom: '1px solid #FDC83E', padding: '25px 0px 20px 0px' }}>
        <Container>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-6">
               <img src={logo} className="logo-footer"  alt="MaybankHeart Logo" />
               </div>
            <div className="col-md-6 col-sm-6 col-xs-6">
              <div className="social-icon-adjust">
                <span><a href="https://www.facebook.com/Maybank " target="_blank" rel="noopener noreferrer"><img src={facebook} className="socialIcons" alt="MaybankHeart Facebook" /></a> </span>
                <span><a href="https://twitter.com/MyMaybank?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor " target="_blank" rel="noopener noreferrer"><img src={twitter} className="socialIcons" alt="MaybankHeart Twitter" /></a></span>
                <span><a href="https://www.instagram.com/mymaybank/" target="_blank" rel="noopener noreferrer"><img src={instagram} className="socialIcons" alt="MaybankHeart Instagram" /></a></span>
                <span><a href="https://www.youtube.com/user/MaybankVideos" target="_blank" rel="noopener noreferrer"><img src={youtube} className="socialIcons" alt="MaybankHeart Youtube" /></a></span>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ borderBottom: '1px solid #FDC83E', padding: '25px 0px 20px 0px' }}>
        <Container>
          <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <div className="footer-label-head">ABOUT</div>
                  <div className="footer-label" onClick={go2FAQPage}>FAQ</div>
                  <div className="footer-label" onClick={go2LoginPage}>Login</div>
                  <div className="footer-label" onClick={go2SignUpPage}>Sign up</div>
                </div>
                {/* { labels ?   */}
                <div className="col-md-4">
                  <div className="footer-label-head">CAMPAIGN</div>
                  <div className="footer-label" onClick={() => go2CampaignsPage('Trending')}>Trending</div>
                  <div className="footer-label" onClick={() => go2CampaignsPage('Ending Soon')}>Ending Soon</div>
                  <div className="footer-label" onClick={() => go2CampaignsPage('Completed')}>Completed</div>
                </div>
                {/* : null } */}
                <div className="col-md-4">
                  <div className="footer-label-head">NGO</div>
                  <div className="footer-label" onClick={go2NGOPage}>NGOs With Us</div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="footer-email-box">
                <div className="footer-label-head">For any queries,</div>
                <div><span className="footer-label" style={{ paddingRight: '3px' }}>Please drop us an email at</span><span >
                  <a className="footer-email-link" href="mailto:maybankheart@maybank.com" data-rel="external">maybankheart@maybank.com</a>
                  </span></div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
        <Container>
          <div className="row" style={{ marginLeft: '0px', marginRight: '0px', textAlign: 'center', fontSize: '12px' }}>
            <div className="col-md-12">© 2021: Malayan Banking Berhad (Company No. 196001000142). All rights reserved.</div>
            <div className="col-md-12">Best viewed on the latest versions of Chrome, Firefox, Edge and Safari.</div>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default DemoFooter;
