
import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import config from '../../config/config';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css';
// nodejs library that concatenates strings
import classnames from "classnames";
import logoWhite from '../../assets/img/maybankheart-logo.svg';
import logoBlack from '../../assets/img/maybankheart-logo-black.svg';
import { useSelector } from "react-redux";

// reactstrap components
import {
  Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";

function ExamplesNavbar() {
  const history = useHistory();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [displayName, setDisplayName] = React.useState('');

  const user_data = useSelector(state => state.user.user_data);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };
  const logout = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    axios.post(config.APP_URL + "logout", {
      headers: headers
    }).then(res => {
      if (res.status === 200) {
        toastr.success('Logout successful');
        window.localStorage.removeItem('userDetails');
        setDisplayName('');
        history.push("/");
      }
    }).catch(e => {
      console.log(e);
    });
  };

  const go2CampaignsPage = () => {
    history.push("/campaigns-page");
  };
  const go2LoginPage = () => {
    history.push("/login-page");
  };
  const go2SignUpPage = () => {
    history.push("/register-page");
  };
  const go2NgoPage = () => {
    history.push("/ngo-page");
  };
  const go2FaqPage = () => {
    history.push("/faq-page");
  };
  React.useEffect(() => {
    const loginCheck = () => {
      let accessToken;
      if(user_data){
        accessToken = user_data.token;
      }
      axios.get(config.APP_URL + 'is-login', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + accessToken,
        },
      }).then(res => {
        if (res.data.status === 'success') {
          setDisplayName(res.data.displayName);
        } else {
          setDisplayName('');
        }
      }).catch(e => console.log(e));
    }
    loginCheck();
  }, [user_data]);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/"
            title="MaybankHeart"
          >
            <img src={logoWhite} className="whiteLogo"  alt="MaybankHeart Logo" />
          </NavbarBrand>
          <NavbarBrand
            data-placement="bottom"
            href="/"
            title="MaybankHeart"
          >
            <img src={logoBlack} className="blackLogo"  alt="MaybankHeart Logo" />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink className="navlinkcustome" href="" onClick={go2CampaignsPage}>
               <div>CAMPAIGNS</div>
               {config.campaignPageHeader === 'yes' ? <div className="nav-border-bottom"><span></span></div> : null}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="navlinkcustome" href="" onClick={go2NgoPage}>
               <div>NGOs</div>
               {config.ngoPageHeader === 'yes' ? <div className="nav-border-bottom"><span></span></div> : null}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="navlinkcustome" href="" onClick={go2FaqPage}>
                <div>FAQ
                </div>
                {config.faqPageHeader === 'yes' ? <div className="nav-border-bottom"><span></span></div> : null}
              </NavLink>
            </NavItem>
            {displayName === '' ?
              <NavItem>
                <NavLink className="navlinkcustome" href="" onClick={go2LoginPage}>
                  <div>LOGIN </div>
                  {config.loginPageHeader === 'yes' ? <div className="nav-border-bottom"><span></span></div> : null}
              </NavLink>
              </NavItem> : null}
            {displayName === '' ?
              <NavItem className="margnRt30">
                <NavLink className={'navlinkcustome signupborder'} href="" onClick={go2SignUpPage}>
                 <div>SIGN UP</div>
                 {config.signupPageHeader === 'yes' ? <div className="nav-border-bottom"><span></span></div> : null}
              </NavLink>
              </NavItem> : null}
            {displayName !== '' ?
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  aria-expanded={false}
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="dropdownMenuButton"
                  nav
                  onClick={(e) => e.preventDefault()}
                  role="button"
                >
                  Hi, {displayName}
                </DropdownToggle>
                <DropdownMenu
                  aria-labelledby="dropdownMenuButton"
                  className="dropdown-info"
                >
                  <DropdownItem
                    href=""
                    onClick={logout}
                  >
                    Logout
                </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              : null}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;
