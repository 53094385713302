import React from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import sha256_digest from "../../components/Scripts/satellizerSHA256";
import {Button, Card, Form, Row, Col} from "reactstrap";
import toastr from "toastr";
import "../../assets/css/style.css";
import config from "../../config/config";

// import facebook from '../../assets/img/facebook-icon.svg';
// import google from '../../assets/img/google-icon.svg';
import close from "../../assets/img/close.svg";

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader";
import DemoFooter from "components/Footers/DemoFooter.js";
import FacebookButton from "../../components/Buttons/FacebookButton";
import GoogleButton from "../../components/Buttons/GoogleButton";

import userObjectAction from "../../actions/userAction";
import {connect} from "react-redux";

class LoginPage extends React.Component {
  constructor() {
    super();
    this.go2HomePage = this.go2HomePage.bind(this);
    this.goSignUpPage = this.goSignUpPage.bind(this);
    this.go2Forfotpasswd = this.go2Forfotpasswd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      loginfields: {email: "", password: ""},
      errors: {},
      hide: true,
    };
  }

  componentDidMount() {
    config.loginPageHeader = "yes";
  }

  go2HomePage() {
    this.props.history.push("/");
  };

  goSignUpPage() {
    config.loginPageHeader = "no";
    this.props.history.push("/register-page");
  };

  go2Forfotpasswd() {
    this.props.history.push("/forgot-password");
  }

  handleChange(e) {
    let loginfields = this.state.loginfields;
    loginfields[e.target.name] = e.target.value;
    this.setState({
      loginfields,
    });
  }

  hidePassword = () => this.setState(({hide}) => ({
    hide: !hide,
  }));

  handleValidation() {
    let loginfields = this.state.loginfields;
    let errors = {};
    let formIsValid = true;

    if (!loginfields["email"]) {
      formIsValid = false;
      errors["email"] = "*Your email address is required";
    } else if (!loginfields["email"].match(
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      formIsValid = false;
      errors["email"] = "*Your email address is invalid.";

    }
    if (!loginfields["password"]) {
      formIsValid = false;
      errors["password"] = "*Password is required.";
    } else if (!loginfields["password"].match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      formIsValid = false;
      errors["password"] = "*Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  formSubmit(e) {
    e.preventDefault();

    if (navigator.cookieEnabled) {
      let _campDetails;
      if (this.props.campaign_details.campaign_data) {
        _campDetails = this.props.campaign_details.campaign_data;
      }
      if (this.handleValidation()) {
        const headers = {
          "Content-Type": "application/json",
        };
        const data = {
          email: this.state.loginfields.email,
          password: sha256_digest(
              JSON.stringify(this.state.loginfields.password)),
        };
        ReactGA.event({
          category: "User",
          action: "Login",
          label: this.state.loginfields.email,
        });
        axios.post(config.APP_URL + "auth/login", data, {
          headers: headers,
        }).then(res => {
          if (res.status === 200) {
            this.props.userObjectAction(res.data);
            toastr.success("Login successful");
            if (config.loginCheckId === "login-first") {
              this.props.history.push("/");
            } else {
              this.props.history.push({
                pathname: "/support-page",
                campaignData: _campDetails,
                displayName: res.data.user_data.displayName,
              });
            }
          } else {
            toastr.error(res.data.message);
          }
        }).catch(e => {
          toastr.error(e.response.data.message);
        });

      } else {
        console.log("Form has errors.");
      }
    } else {
      toastr.error(
          "Your browser has cookies disabled. Please make sure that your cookies are enabled and try again, otherwise you may choose to donate anonymously.");
    }
  }

  render() {
    return <div style={{background: "#F0F0F0"}}>
      <ExamplesNavbar/>
      <LandingPageHeader/>
      <div className="container">
        <Row>
          <Col className="ml-auto mr-auto" lg="4">
            <Card className="card-register ml-auto mr-auto">
              <div><span className="title-login mx-auto">LOGIN</span><span
                  style={{float: "right"}}> <img src={close}
                                                 onClick={this.go2HomePage}
                                                 style={{
                                                   width: "18px",
                                                   cursor: "pointer",
                                                 }} alt="ngo"/></span></div>
              <div className="withursocintwrk">with your social network</div>
              <div className="text-center"
                   style={{marginBottom: "15px", marginTop: "-8px"}}>
                <FacebookButton success={this.go2HomePage}/>
                <GoogleButton success={this.go2HomePage}/>
              </div>
              <div className="weneverpost"> We’ll never post anything without
                your permission
              </div>
              <div><p className="pclass"><span className="Orclass">or</span></p>
              </div>
              <Form className="register-form"
                    onSubmit={this.formSubmit.bind(this)}>
                <div style={{marginBottom: "15px"}}>
                  <input type="text" placeholder="Email" className="login-field"
                         name="email"
                         onChange={this.handleChange}
                         value={this.state.loginfields.email}
                  />
                  <div className="errMsg">{this.state.errors.email}</div>
                </div>
                <div className="input-text">
                  <input type={this.state.hide ? "password" : "input"}
                         placeholder="Password" className="login-field"
                         name="password"
                         autoComplete={"current-password"}
                         onChange={this.handleChange}
                         value={this.state.loginfields.password}
                  />
                  <i className={`fa ${this.state.hide === true ?
                      "fa-eye-slash" :
                      "fa-eye"} password-eye`}
                     onClick={this.hidePassword}></i>
                  <div className="errMsg">{this.state.errors.password}</div>
                </div>
                <Button block className="support-button-payment" type="submit"
                        style={{marginTop: "15px"}}>
                  LOGIN
                </Button>
              </Form>
              <div>
                <Button className="forgotpasswd" onClick={this.go2Forfotpasswd}>
                  Forgot password?
                </Button>
              </div>
              <div style={{paddingTop: "20px"}}><p className="pclass"/></div>
              <div style={{marginTop: "-5px"}}><span className="getsupt"> New to MaybankHeart?</span>
                <span className="signupasNgo" onClick={this.goSignUpPage}>Sign Up!</span>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <DemoFooter/>
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    campaign_details: state.campaign,
  };
};

const mapDispatchToProps = dispatch => ({
  userObjectAction: (payload) => dispatch(userObjectAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
